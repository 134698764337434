body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-blob {
	border-radius: 50%;
	margin: 10px;
	height: 15px;
	width: 15px;
	position: absolute;
	top: 0;
	right: 0;
	box-shadow: 0 0 0 0 #4fa355;
	transform: scale(1);
	animation: customPulse 2s infinite;
}

@keyframes customPulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #4fa355;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px #fd7d1400;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #fd7d1400;
	}
}