/* COLORS */
$primary: #4fa355;
$accent: #292C7E;
$danger: #ea0707;
$secondary: #010101;
$primary-hover: #438547;
$secondary-hover: #af5644;
$bg-color: #EEEEEE;
$white: #FFFFFF;


$headings:  Fellix, 'Fellix', serif, Arial;
$content: Fellix, 'Fellix', serif, Arial;