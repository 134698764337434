a {
    text-decoration: none;
}

img {
    width: 100%;
}



.bg-purple-light {
    background-color: #e4d3ff
}

.bg-purple-80 {
    background-color: #efe5ff
}

.bg-green-light {
    background-color: #acffd3
}

.bg-green-80 {
    background-color: #d3ffe7
}

.bg-orange-light {
    background-color: #ffe4cd
}

.bg-orange-80 {
    background-color: #ffedde
}

.bg-black-800 {
    background-color: #121111
}

.bg-black-600 {
    background-color: #1e1e1e
}

.text-black-800 {
    color: #121111
}

.text-black-600 {
    color: #1e1e1e
}

.text-gray-150 {
    color: #adb5bd
}

.text-gray-600 {
    color: #6c757d !important
}

.text-gray-700 {
    color: #495057 !important
}

.text-orange-500 {
    color: #fd7e14
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.green-hover:hover {
    background-color: #4dd4ac
}

.green-hover:hover svg path {
    fill: #fff
}

.icon-blue-hover>.dropdown>a:hover svg path,
.icon-blue-hover>a:hover svg path {
    fill: $primary
}

.bg-white-90 {
    background-color: rgba(255, 255, 255, .9) !important
}

.bg-gray-50 {
    background-color: #e9ecef !important
}

.bg-gray-100 {
    background-color: #f8f9fa !important
}

.bg-gray-200 {
    background-color: #e9ecef
}

.bg-gray-300 {
    background-color: #dee2e6
}

.bg-gray-400 {
    background-color: #ced4da
}

.bg-gray-500 {
    background-color: #adb5bd
}

.bg-gray-600 {
    background-color: #6c757d
}

.bg-gray-700 {
    background-color: $secondary
}

.bg-gray-800 {
    background-color: #343a40
}

.bg-gray-900 {
    background-color: #212529
}

.bg-blue-50 {
    background-color: #e6f0ff
}

.bg-blue-100 {
    background-color: #cfe2ff
}

.bg-blue-200 {
    background-color: #9ec5fe
}

.bg-blue-300 {
    background-color: #6ea8fe
}

.bg-blue-400 {
    background-color: #3d8bfd
}

.bg-blue-500 {
    background-color: $primary
}

.bg-blue-600 {
    background-color: #0a58ca
}

.bg-blue-700 {
    background-color: #084298
}

.bg-blue-800 {
    background-color: #052c65
}

.bg-blue-900 {
    background-color: #031633
}

.bg-indigo-50 {
    background-color: #e8ddfd
}

.bg-indigo-100 {
    background-color: #e0cffc
}

.bg-indigo-200 {
    background-color: #c29ffa
}

.bg-indigo-300 {
    background-color: #a370f7
}

.bg-indigo-400 {
    background-color: #8540f5
}

.bg-indigo-500 {
    background-color: #6610f2
}

.bg-indigo-600 {
    background-color: #520dc2
}

.bg-indigo-700 {
    background-color: #3d0a91
}

.bg-indigo-800 {
    background-color: #290661
}

.bg-indigo-900 {
    background-color: #140330
}

.bg-indigo-light {
    background-color: #975cf6
}

.bg-purple-50 {
    background-color: #e8ddff
}

.bg-purple-100 {
    background-color: #e2d9f3
}

.bg-purple-200 {
    background-color: #c5b3e6
}

.bg-purple-300 {
    background-color: #a98eda
}

.bg-purple-400 {
    background-color: #8c68cd
}

.bg-purple-500 {
    background-color: #6f42c1
}

.bg-purple-600 {
    background-color: #59359a
}

.bg-purple-700 {
    background-color: #432874
}

.bg-purple-800 {
    background-color: #2c1a4d
}

.bg-purple-900 {
    background-color: #160d27
}

.bg-pink-50 {
    background-color: #fde
}

.bg-pink-100 {
    background-color: #f7d6e6
}

.bg-pink-200 {
    background-color: #efadce
}

.bg-pink-300 {
    background-color: #e685b5
}

.bg-pink-400 {
    background-color: #de5c9d
}

.bg-pink-500 {
    background-color: #d63384
}

.bg-pink-600 {
    background-color: #ab296a
}

.bg-pink-700 {
    background-color: #801f4f
}

.bg-pink-800 {
    background-color: #561435
}

.bg-pink-900 {
    background-color: #2b0a1a
}

.bg-red-50 {
    background-color: #ffe1e3
}

.bg-red-100 {
    background-color: #f8d7da
}

.bg-red-200 {
    background-color: #f1aeb5
}

.bg-red-300 {
    background-color: #ea868f
}

.bg-red-400 {
    background-color: #e35d6a
}

.bg-red-500 {
    background-color: #dc3545
}

.bg-red-600 {
    background-color: #b02a37
}

.bg-red-700 {
    background-color: #842029
}

.bg-red-800 {
    background-color: #58151c
}

.bg-red-900 {
    background-color: #2c0b0e
}

.bg-orange-50 {
    background-color: #ffead7
}

.bg-orange-100 {
    background-color: #ffe5d0
}

.bg-orange-200 {
    background-color: #fecba1
}

.bg-orange-300 {
    background-color: #feb272
}

.bg-orange-400 {
    background-color: #fd9843
}

.bg-orange-500 {
    background-color: #fd7e14
}

.bg-orange-600 {
    background-color: #ca6510
}

.bg-orange-700 {
    background-color: #984c0c
}

.bg-orange-800 {
    background-color: #653208
}

.bg-orange-900 {
    background-color: #331904
}

.bg-yellow-50 {
    background-color: #faf0cd
}

.bg-yellow-100 {
    background-color: #fff3cd
}

.bg-yellow-200 {
    background-color: #ffe69c
}

.bg-yellow-300 {
    background-color: #ffda6a
}

.bg-yellow-400 {
    background-color: #ffcd39
}

.bg-yellow-500 {
    background-color: #ffc107
}

.bg-yellow-600 {
    background-color: #cc9a06
}

.bg-yellow-700 {
    background-color: #997404
}

.bg-yellow-800 {
    background-color: #664d03
}

.bg-yellow-900 {
    background-color: #332701
}

.bg-green-50 {
    background-color: #ddf4e2
}

.bg-green-100 {
    background-color: #d1e7dd
}

.bg-green-200 {
    background-color: #a3cfbb
}

.bg-green-300 {
    background-color: #75b798
}

.bg-green-400 {
    background-color: #479f76
}

.bg-green-500 {
    background-color: #198754
}

.bg-green-600 {
    background-color: #146c43
}

.bg-green-700 {
    background-color: #0f5132
}

.bg-green-800 {
    background-color: #0a3622
}

.bg-green-900 {
    background-color: #051b11
}

.bg-teal-50 {
    background-color: #dcfff5
}

.bg-teal-100 {
    background-color: #d2f4ea
}

.bg-teal-200 {
    background-color: #a6e9d5
}

.bg-teal-300 {
    background-color: #79dfc1
}

.bg-teal-400 {
    background-color: #4dd4ac
}

.bg-teal-500 {
    background-color: #20c997
}

.bg-teal-600 {
    background-color: #1aa179
}

.bg-teal-700 {
    background-color: #13795b
}

.bg-teal-800 {
    background-color: #0d503c
}

.bg-teal-900 {
    background-color: #06281e
}

.bg-cyan-50 {
    background-color: #dafbff
}

.bg-cyan-100 {
    background-color: #cff4fc
}

.bg-cyan-200 {
    background-color: #9eeaf9
}

.bg-cyan-300 {
    background-color: #6edff6
}

.bg-cyan-400 {
    background-color: #3dd5f3
}

.bg-cyan-500 {
    background-color: #0dcaf0
}

.bg-cyan-600 {
    background-color: #0aa2c0
}

.bg-cyan-700 {
    background-color: #087990
}

.bg-cyan-800 {
    background-color: #055160
}

.bg-cyan-900 {
    background-color: #032830
}

.bg-black {
    background-color: #000
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:hover,
.show>.btn-secondary.dropdown-toggle {
    background-color: #343a40 !important;
    border-color: #343a40 !important
}

.bg-light-100 {
    background-color: #f3f3f3
}

.text-muted {
    color: #adb5bd !important
}

.text-black {
    color: #000
}

.blue-dark {
    color: $secondary
}

.link-success {
    color: #4dd4ac
}

.btn-soft-primary {
    color: $primary
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #1e1e1e
}

body, p {
    font-family: $content;
}

.text-secondary {
    color: $secondary !important
}

.text-danger {
    color: #e35d6a !important
}

.text-success {
    color: #4dd4ac !important
}

.text-blue-600 {
    color: #0a58ca
}

.text-warning {
    color: #ffc107 !important
}

.text-info {
    color: #0dcaf0 !important
}

.text-light {
    color: #f8fcfc !important
}

.text-dark {
    color: #212529 !important
}

.text-teal-500 {
    color: #20c997
}

.text-dnd {
    color: #e25563
}

hr {
    border-color: #dee2e6
}

.border-black {
    border-color: #000 !important
}

.border-secondary {
    border-color: $secondary !important
}

.border-success {
    border-color: #4dd4ac !important
}

.border-danger {
    border-color: #e35d6a !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-dark {
    border-color: #212529 !important
}

.border-gray-50 {
    border-color: #e9ecef !important
}

.border-gray-100 {
    border-color: #f8f9fa !important
}

.border-gray-200 {
    border-color: #e9ecef !important
}

.border-gray-300 {
    border-color: #dee2e6 !important
}

.border-gray-400 {
    border-color: #ced4da !important
}

.border-gray-500 {
    border-color: #adb5bd !important
}

.border-gray-600 {
    border-color: #6c757d !important
}

.border-gray-700 {
    border-color: $secondary !important
}

.border-gray-800 {
    border-color: #343a40 !important
}

.border-gray-900 {
    border-color: #212529 !important
}

.border-blue-50 {
    border-color: #e6f0ff !important
}

.border-blue-100 {
    border-color: #cfe2ff !important
}

.border-blue-200 {
    border-color: #9ec5fe !important
}

.border-blue-300 {
    border-color: #6ea8fe !important
}

.border-blue-400 {
    border-color: #3d8bfd !important
}

.border-blue-500 {
    border-color: $primary !important
}

.border-blue-600 {
    border-color: #0a58ca !important
}

.border-blue-700 {
    border-color: #084298 !important
}

.border-blue-800 {
    border-color: #052c65 !important
}

.border-blue-900 {
    border-color: #031633 !important
}

.border-indigo-50 {
    border-color: #e8ddfd !important
}

.border-indigo-100 {
    border-color: #e0cffc !important
}

.border-indigo-200 {
    border-color: #c29ffa !important
}

.border-indigo-300 {
    border-color: #a370f7 !important
}

.border-indigo-400 {
    border-color: #8540f5 !important
}

.border-indigo-500 {
    border-color: #6610f2 !important
}

.border-indigo-600 {
    border-color: #520dc2 !important
}

.border-indigo-700 {
    border-color: #3d0a91 !important
}

.border-indigo-800 {
    border-color: #290661 !important
}

.border-indigo-900 {
    border-color: #140330 !important
}

.border-purple-50 {
    border-color: #e8ddff !important
}

.border-purple-100 {
    border-color: #e2d9f3 !important
}

.border-purple-200 {
    border-color: #c5b3e6 !important
}

.border-purple-300 {
    border-color: #a98eda !important
}

.border-purple-400 {
    border-color: #8c68cd !important
}

.border-purple-500 {
    border-color: #6f42c1 !important
}

.border-purple-600 {
    border-color: #59359a !important
}

.border-purple-700 {
    border-color: #432874 !important
}

.border-purple-800 {
    border-color: #2c1a4d !important
}

.border-purple-900 {
    border-color: #160d27 !important
}

.border-pink-50 {
    border-color: #fde !important
}

.border-pink-100 {
    border-color: #f7d6e6 !important
}

.border-pink-200 {
    border-color: #efadce !important
}

.border-pink-300 {
    border-color: #e685b5 !important
}

.border-pink-400 {
    border-color: #de5c9d !important
}

.border-pink-500 {
    border-color: #d63384 !important
}

.border-pink-600 {
    border-color: #ab296a !important
}

.border-pink-700 {
    border-color: #801f4f !important
}

.border-pink-800 {
    border-color: #561435 !important
}

.border-pink-900 {
    border-color: #2b0a1a !important
}

.border-red-50 {
    border-color: #ffe1e3 !important
}

.border-red-100 {
    border-color: #f8d7da !important
}

.border-red-200 {
    border-color: #f1aeb5 !important
}

.border-red-300 {
    border-color: #ea868f !important
}

.border-red-400 {
    border-color: #e35d6a !important
}

.border-red-500 {
    border-color: #dc3545 !important
}

.border-red-600 {
    border-color: #b02a37 !important
}

.border-red-700 {
    border-color: #842029 !important
}

.border-red-800 {
    border-color: #58151c !important
}

.border-red-900 {
    border-color: #2c0b0e !important
}

.border-orange-50 {
    border-color: #ffead7 !important
}

.border-orange-100 {
    border-color: #ffe5d0 !important
}

.border-orange-200 {
    border-color: #fecba1 !important
}

.border-orange-300 {
    border-color: #feb272 !important
}

.border-orange-400 {
    border-color: #fd9843 !important
}

.border-orange-500 {
    border-color: #fd7e14 !important
}

.border-orange-600 {
    border-color: #ca6510 !important
}

.border-orange-700 {
    border-color: #984c0c !important
}

.border-orange-800 {
    border-color: #653208 !important
}

.border-orange-900 {
    border-color: #331904 !important
}

.border-yellow-50 {
    border-color: #faf0cd !important
}

.border-yellow-100 {
    border-color: #fff3cd !important
}

.border-yellow-200 {
    border-color: #ffe69c !important
}

.border-yellow-300 {
    border-color: #ffda6a !important
}

.border-yellow-400 {
    border-color: #ffcd39 !important
}

.border-yellow-500 {
    border-color: #ffc107 !important
}

.border-yellow-600 {
    border-color: #cc9a06 !important
}

.border-yellow-700 {
    border-color: #997404 !important
}

.border-yellow-800 {
    border-color: #664d03 !important
}

.border-yellow-900 {
    border-color: #332701 !important
}

.border-green-50 {
    border-color: #ddf4e2 !important
}

.border-green-100 {
    border-color: #d1e7dd !important
}

.border-green-200 {
    border-color: #a3cfbb !important
}

.border-green-300 {
    border-color: #75b798 !important
}

.border-green-400 {
    border-color: #479f76 !important
}

.border-green-500 {
    border-color: #198754 !important
}

.border-green-600 {
    border-color: #146c43 !important
}

.border-green-700 {
    border-color: #0f5132 !important
}

.border-green-800 {
    border-color: #0a3622 !important
}

.border-green-900 {
    border-color: #051b11 !important
}

.border-teal-50 {
    border-color: #dcfff5 !important
}

.border-teal-100 {
    border-color: #d2f4ea !important
}

.border-teal-200 {
    border-color: #a6e9d5 !important
}

.border-teal-300 {
    border-color: #79dfc1 !important
}

.border-teal-400 {
    border-color: #4dd4ac !important
}

.border-teal-500 {
    border-color: #20c997 !important
}

.border-teal-600 {
    border-color: #1aa179 !important
}

.border-teal-700 {
    border-color: #13795b !important
}

.border-teal-800 {
    border-color: #0d503c !important
}

.border-teal-900 {
    border-color: #06281e !important
}

.border-black-600 {
    border-color: #1e1e1e !important
}

.border-cyan-50 {
    border-color: #dafbff !important
}

.border-cyan-100 {
    border-color: #cff4fc !important
}

.border-cyan-200 {
    border-color: #9eeaf9 !important
}

.border-cyan-300 {
    border-color: #6edff6 !important
}

.border-cyan-400 {
    border-color: #3dd5f3 !important
}

.border-cyan-500 {
    border-color: #0dcaf0 !important
}

.border-cyan-600 {
    border-color: #0aa2c0 !important
}

.border-cyan-700 {
    border-color: #087990 !important
}

.border-cyan-800 {
    border-color: #055160 !important
}

.border-cyan-900 {
    border-color: #032830 !important
}

.Muli-font {
    font-family: $headings
}

.open-sans-font {
    font-family: $content
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    font-family: $headings
}

.btn,
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
    font-family: $headings
}

.font-weight-semibold {
    font-weight: 600 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-medium {
    font-weight: 500 !important
}

.font-weight-normal,
.font-weight-regular {
    font-weight: 400 !important
}

.font-weight-light {
    font-weight: 300 !important
}

.display-1 {
    font-size: 6rem;
    font-weight: 700
}

.display-2 {
    font-size: 5.5rem;
    line-height: 5.75rem;
    font-weight: 700
}

.display-3 {
    font-size: 4.5rem;
    line-height: 5rem;
    font-weight: 700
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 700
}

.display-5 {
    font-size: 2.875rem;
    font-weight: 700
}

.display-6 {
    font-size: 2.25rem;
    font-weight: 700
}

.fs-24 {
    font-size: 1.5rem !important
}

.fs-32 {
    font-size: 2rem !important
}

.fs-20 {
    font-size: 1.25rem
}

.h2,
h2 {
    line-height: 3rem
}

.h3,
h3 {
    line-height: 2.5rem
}

.h4,
h4 {
    line-height: 2.25rem
}

.font-size-16,
big.font-size-16,
body {
    font-size: 1rem
}

.big,
big {
    font-size: 1.25rem;
    line-height: 2rem
}

.fs-18 {
    font-size: 1.125rem !important
}

.fs-16 {
    font-size: 1rem !important
}

.small,
small {
    font-size: .8125rem
}

.caption,
caption {
    font-size: .875rem !important
}

.fs-11 {
    font-size: .6875rem !important
}

.fs-12 {
    font-size: .75rem
}

.tiny {
    font-size: .625rem
}

.component-list:after {
    display: block;
    clear: both;
    content: ""
}

.rounded-3 {
    border-radius: .1875rem !important
}

.rounded-6 {
    border-radius: .375rem !important
}

.rounded-12 {
    border-radius: .75rem !important
}

.rounded-24 {
    border-radius: 1.5rem !important
}

.rounded-29 {
    border-radius: 1.8125rem !important
}

.rounded-40 {
    border-radius: 2.5rem !important
}

.rounded-top {
    border-radius: .25rem .25rem 0 0 !important
}

.rounded-top-6 {
    border-radius: .375rem .375rem 0 0 !important
}

.rounded-bottom {
    border-radius: 0 0 .25rem .25rem !important
}

.rounded-left {
    border-radius: .25rem 0 0 .25rem !important
}

.rounded-left-6 {
    border-radius: .375rem 0 0 .375rem !important
}

.rounded-right-6 {
    border-radius: 0 .375rem .375rem 0 !important
}

.rounded-right {
    border-radius: 0 .25rem .25rem 0 !important
}

.rounded-pill-left {
    border-radius: 3.125rem 0 0 3.125rem !important
}

.rounded-top-left {
    border-radius: .8125rem 0 0 0
}

.shadow-none {
    box-shadow: none
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .1) !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .04) !important
}

.hover-shadow-primary:focus,
.hover-shadow-primary:hover {
    box-shadow: 0 .25rem .6875rem rgba(13, 110, 253, .35)
}

.hover-shadow-success:focus,
.hover-shadow-success:hover {
    box-shadow: 0 .25rem .6875rem rgba(77, 212, 172, .35)
}

.hover-shadow-danger:focus,
.hover-shadow-danger:hover {
    box-shadow: 0 .25rem .6875rem rgba(227, 93, 106, .35)
}

.hover-shadow-info:focus,
.hover-shadow-info:hover {
    box-shadow: 0 .25rem .6875rem rgba(13, 202, 240, .35)
}

.hover-shadow-secondary:focus,
.hover-shadow-secondary:hover {
    box-shadow: 0 .25rem .6875rem rgba(73, 80, 87, .35)
}

.hover-shadow-warning:focus {
    box-shadow: 0 .25rem .6875rem rgba(255, 193, 7, .35)
}

.hover-shadow-dark:focus,
.hover-shadow-dark:hover {
    box-shadow: 0 .25rem .6875rem rgba(33, 37, 41, .35)
}

.hover-shadow-light:focus,
.hover-shadow-light:hover {
    box-shadow: 0 .25rem .6875rem rgba(248, 252, 252, .35)
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active,
.btn-outline-secondary:hover {
    background-color: $secondary;
    border-color: $secondary
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active,
.btn-outline-danger:hover {
    background-color: #e35d6a;
    border-color: #e35d6a
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active,
.btn-outline-success:hover {
    background-color: #4dd4ac;
    border-color: #4dd4ac
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active,
.btn-outline-warning:hover {
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active,
.btn-outline-info:hover {
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active,
.btn-outline-dark:focus,
.btn-outline-dark:hover {
    background-color: transparent;
    border-color: $primary !important;
    color: $primary !important
}

.btn-check:active+.btn-outline-dark svg path,
.btn-check:checked+.btn-outline-dark svg path,
.btn-outline-dark.active svg path,
.btn-outline-dark.dropdown-toggle.show svg path,
.btn-outline-dark:active svg path,
.btn-outline-dark:focus svg path,
.btn-outline-dark:hover svg path {
    fill: $primary
}

.card-hover:hover {
    box-shadow: 0 .25rem .6875rem rgba(33, 37, 41, .15)
}

.shadow-20 {
    box-shadow: 0 0 .125rem rgba(89, 53, 154, .2)
}

.shadow-40 {
    box-shadow: 0 .125rem .25rem rgba(89, 53, 154, .2)
}

.shadow-60 {
    box-shadow: 0 .25rem .4375rem rgba(89, 53, 154, .2)
}

.shadow-80 {
    box-shadow: 0 .3125rem .625rem rgba(89, 53, 154, .2)
}

.shadow-100 {
    box-shadow: 0 .375rem .875rem rgba(89, 53, 154, .2)
}

.shadow-top-20 {
    box-shadow: 0 -.03125rem .125rem rgba(89, 53, 154, .2)
}

.shadow-top-40 {
    box-shadow: 0 -.125rem .25rem rgba(89, 53, 154, .2)
}

.shadow-top-60 {
    box-shadow: 0 -.25rem .5rem rgba(89, 53, 154, .2)
}

.shadow-top-80 {
    box-shadow: 0 -.5rem 1rem rgba(89, 53, 154, .2)
}

.shadow-top-100 {
    box-shadow: 0 -1rem 1.5rem rgba(89, 53, 154, .2)
}

.shadow-dark-20 {
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, .4)
}

.shadow-dark-40 {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .4)
}

.shadow-dark-60 {
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .4)
}

.shadow-dark-80 {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .04)
}

.shadow-dark-100 {
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, .4)
}

.shadow-dark-top-20 {
    box-shadow: 0 -.0625rem .125rem rgba(0, 0, 0, .4)
}

.shadow-dark-top-40 {
    box-shadow: 0 -.125rem .25rem rgba(0, 0, 0, .4)
}

.shadow-dark-top-60 {
    box-shadow: 0 -.25rem .5rem rgba(0, 0, 0, .4)
}

.shadow-dark-top-80 {
    box-shadow: 0 -.5rem 1rem rgba(0, 0, 0, .4)
}

.shadow-dark-top-100 {
    box-shadow: 0 -1rem 1.5rem rgba(0, 0, 0, .4)
}

.shadow-light-top {
    box-shadow: 0 -.375rem .75rem rgba(89, 53, 154, .2)
}

.shadow-light-bottom {
    box-shadow: 0 .375rem .75rem rgba(89, 53, 154, .2)
}

.shadow-dark-top {
    box-shadow: 0 -.375rem .75rem rgba(0, 0, 0, .4)
}

.shadow-dark-bottom {
    box-shadow: 0 .375rem .75rem rgba(0, 0, 0, .4)
}

.Fabrx-components+.Fabrx-components {
    padding-top: 6.25rem
}

.component-item+.component-item {
    padding-top: 3.125rem
}

.Fabrx-section {
    padding: 5rem 0
}

.avatar {
    position: relative;
    display: inline-block;
    width: 3.125rem;
    height: 3.125rem;
    min-width: 3.125rem;
    border-radius: .375rem
}

.avatar .avatar-img {
    border-radius: .375rem;
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar.avatar-center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
}

.avatar.avatar-circle {
    border-radius: 50%
}

.avatar.avatar-circle .avatar-img,
.avatar.avatar-circle .avatar-initials {
    border-radius: 50%
}

.avatar.avatar-circle .avatar-status {
    bottom: 0;
    right: -.125rem
}

.avatar.avatar-circle .avatar-xxl-status {
    bottom: 0;
    right: .125rem
}

.avatar.avatar-circle .avatar-xl-status {
    bottom: 0;
    right: .0625rem
}

.avatar.avatar-circle .avatar-lg-status {
    bottom: 0;
    right: 0
}

.avatar.avatar-circle .avatar-sm-status {
    bottom: 0;
    right: 0
}

.avatar.avatar-xxl {
    width: 7rem;
    height: 7rem;
    min-width: 7rem
}

.avatar.avatar-xl {
    width: 5.625rem;
    min-width: 5.625rem;
    height: 5.625rem
}

.avatar.avatar-xl .avatar-initials {
    font-size: 2rem
}

.avatar.avatar-lg {
    width: 4.25rem;
    min-width: 4.25rem;
    height: 4.25rem
}

.avatar.avatar-lg .avatar-initials {
    font-size: 1.25rem
}

.avatar.avatar-sm {
    width: 2.6875rem;
    min-width: 2.6875rem;
    height: 2.6875rem
}

.avatar.avatar-sm .avatar-initials {
    font-size: .8125rem
}

.avatar.avatar-xs {
    width: 1.75rem;
    min-width: 1.75rem;
    height: 1.75rem
}

.avatar.avatar-xs .avatar-initials {
    font-size: .75rem
}

.avatar.avatar-xss {
    width: 1.125rem;
    min-width: 1.125rem;
    height: 1.125rem
}

.avatar-danger,
.avatar-dark,
.avatar-info,
.avatar-primary,
.avatar-secondary,
.avatar-success {
    color: #fff
}

.avatar-light,
.avatar-warning {
    color: #000
}

.avatar-primary {
    background-color: $primary
}

.avatar-dark-light {
    background-color: #e9ecef
}

.avatar-secondary {
    background-color: $secondary
}

.avatar-offline {
    background-color: #adb5bd
}

.avatar-success {
    background-color: #20c997
}

.avatar-white {
    background-color: #fff
}

.avatar-danger {
    background-color: #e25563
}

.avatar-warning {
    background-color: #fd7e14
}

.avatar-info {
    background-color: #0dcaf0
}

.avatar-light {
    background-color: #f8fcfc
}

.avatar-dark {
    background-color: #212529
}

.avatar-soft-primary {
    background-color: rgba(13, 110, 253, .1);
    color: $primary
}

.avatar-soft-secondary {
    background-color: rgba(73, 80, 87, .1);
    color: $secondary
}

.avatar-soft-success {
    background-color: rgba(77, 212, 172, .1);
    color: #4dd4ac
}

.avatar-soft-danger {
    background-color: rgba(227, 93, 106, .1);
    color: #e35d6a
}

.avatar-soft-warning {
    background-color: rgba(255, 193, 7, .1);
    color: #ffc107
}

.avatar-soft-info {
    background-color: rgba(13, 202, 240, .1);
    color: #0dcaf0
}

.avatar-soft-light {
    background-color: rgba(248, 252, 252, .1);
    color: #f8fcfc
}

.avatar-soft-dark {
    background-color: rgba(33, 37, 41, .1);
    color: #212529
}

.avatar-initials {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-weight: 600
}

.avatar-status {
    position: absolute;
    bottom: -.525rem;
    right: -.525rem;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    vertical-align: baseline;
    border: .125rem solid #fff;
    width: .875rem;
    min-width: .875rem;
    height: .875rem;
    line-height: 1;
    font-size: .625rem;
    border-radius: 50%
}

.avatar-lg-status {
    width: 1.125rem;
    min-width: 1.125rem;
    height: 1.125rem;
    font-size: .75rem
}

.avatar-xl-status {
    width: 1.375rem;
    min-width: 1.375rem;
    height: 1.375rem;
    border-width: .1875rem
}

.avatar-xxl-status {
    width: 1.5625rem;
    min-width: 1.5625rem;
    height: 1.5625rem;
    border-width: .1875rem
}

.avatar-sm-status {
    bottom: -.2625rem;
    right: -.2625rem;
    width: .75rem;
    min-width: .75rem;
    height: .75rem;
    font-size: .5rem
}

.avatar-xs-status {
    width: .5rem;
    min-width: .5rem;
    height: .5rem;
    border-width: .0625rem
}

.status {
    width: .5rem;
    min-width: .5rem;
    height: .5rem;
    border-radius: 50%;
    display: inline-block
}

.status.online {
    background-color: #20c997
}

.status.dnd {
    background-color: #e25563
}

.status.away {
    background-color: #fd933a
}

.status.offline {
    background-color: #adb5bd
}

.avatar-group {
    display: -ms-flexbox;
    display: flex
}

.avatar-group .avatar-xxl .avatar-img,
.avatar-group .avatar-xxl .avatar-initials {
    border: .3125rem solid #fff;
    font-size: 2.5rem;
    font-weight: 700
}

.avatar-group .avatar-xl .avatar-img,
.avatar-group .avatar-xl .avatar-initials {
    border: .25rem solid #fff;
    font-size: 2rem;
    font-weight: 700
}

.avatar-group .avatar-lg .avatar-img,
.avatar-group .avatar-lg .avatar-initials {
    border: .1875rem solid #fff;
    font-size: 1.75rem;
    font-weight: 700
}

.avatar-group .avatar-img,
.avatar-group .avatar-initials {
    border: .1875rem solid #fff;
    font-size: 1rem;
    font-weight: 700
}

.avatar-group .avatar-sm .avatar-img,
.avatar-group .avatar-sm .avatar-initials {
    border: .125rem solid #fff;
    font-size: 1rem;
    font-weight: 700
}

.avatar-group .avatar-xs .avatar-img,
.avatar-group .avatar-xs .avatar-initials {
    border: .0625rem solid #fff;
    font-size: .625rem;
    font-weight: 600
}

.avatar-group .avatar+.avatar {
    margin-left: -1.4375rem
}

.avatar-group .avatar-xxl+.avatar-xxl {
    margin-left: -2.5rem
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -2rem
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -1.25rem
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -1rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.625rem
}

.border-transprant {
    border-color: transparent !important
}

.avatar-border-xs {
    border: .0625rem solid #fff
}

.avatar-border-sm {
    border: .125rem solid #fff !important
}

.avatar-border,
.avatar-border-lg {
    border: .1875rem solid #fff
}

.avatar-border-xl {
    border: .25rem solid #fff
}

.avatar-border-xxl {
    border: .3125rem solid #fff
}

.avatar-centered {
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto
}

.btn {
    font-size: .875rem;
    font-weight: 600;
    text-decoration: none;
    height: 2.375rem;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: .375rem 1.0625rem
}

.btn[data-bs-toggle=collapse] {
    -ms-flex-pack: start;
    justify-content: flex-start;
    height: auto
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-check:focus+.btn,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus,
.btn:focus {
    box-shadow: none
}

.btn-group-lg>.btn,
.btn-lg {
    font-size: 1rem;
    height: 3rem;
    line-height: 1.875rem;
    padding: .5rem 1.4375rem
}

.btn-group>.btn[type=button] {
    padding: .375rem 1.84375rem
}

.btn-group-lg>.btn[type=button] {
    padding: .5rem 2.3125rem
}

.btn-group-xl>.btn,
.btn-xl {
    height: 3.625rem;
    padding: .375rem 2.5rem;
    font-size: 1.25rem;
    line-height: 2.8125rem
}

.btn-group-sm>.btn,
.btn-sm {
    height: 1.9375rem;
    font-size: .625rem;
    line-height: 1.4375rem;
    padding: .25rem .75rem
}

.btn-group-sm>.btn[type=button] {
    padding: .25rem 1.25rem
}

.btn-success {
    color: #fff
}

.btn-link {
    padding: 0 !important;
    height: auto !important;
    line-height: initial !important;
    border: none !important;
    box-shadow: none !important
}

.btn-link:focus {
    box-shadow: none !important
}

.btn-icon {
    font-size: 1.3125rem;
    padding: .5rem .6875rem
}

.btn-icon.btn-xl {
    font-size: 2rem;
    padding: .5rem 1rem
}

.btn-group-lg>.btn-icon.btn,
.btn-icon.btn-lg {
    font-size: 1.4375rem;
    padding: .5rem .8125rem
}

.btn-group-sm>.btn-icon.btn,
.btn-icon.btn-sm {
    font-size: 1.125rem;
    padding: .25rem;
    width: 1.9375rem
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%
}

.media .media-body {
    width: 100%
}

.badge {
    font-weight: 700;
    padding: .625rem .8125rem;
    font-size: .6875rem;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center
}

.badge img {
    vertical-align: top
}

.badge.badge-sm {
    padding: .34375rem .5rem
}

.badge.badge-size-xl {
    width: 5.3125rem;
    height: 5.3125rem
}

.badge-absolute {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 1
}

.btn .badge {
    padding: .25rem .3125rem
}

.badge-primary {
    background-color: $primary
}

.badge-soft-primary {
    background-color: #e6f0ff;
    color: $primary
}

.badge-secondary {
    background-color: $secondary
}

.badge-danger {
    background-color: #e35d6a
}

.badge-success {
    background-color: #4dd4ac
}

.badge-cyan {
    background-color: #0aa2c0
}

.badge-warning {
    background-color: #ffc107;
    color: #1e1e1e
}

.badge-info {
    background-color: #0dcaf0
}

.badge-dark {
    background-color: #1e1e1e
}

.badge-light {
    background-color: #fff;
    color: $accent;
}

.badge-purple {
    background-color: #6f42c1
}

.card-body-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column
}

.text-white .card-title {
    color: #fff
}

.muze-card {
    border: none;
    border-radius: .375rem;
    overflow: hidden;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .04);
    background-color: #fff
}

.muze-card a {
    color: #212529
}

.card-dashed {
    border: none
}

.card-dashed .card-dashed-body {
    border: .0625rem dashed $secondary;
    border-radius: .3125rem;
    transition: .3s
}

.alert {
    font-weight: 600;
    padding: .75rem 1.4rem;
    font-size: 1rem
}

.alert .close {
    opacity: 1;
    background-color: transparent;
    border: none;
    position: absolute;
    top: .1875rem;
    right: .9375rem;
    z-index: 1
}

.alert.alert-success p {
    color: #fff
}

.alert-dismissible .close {
    padding: .75rem 1rem
}

.alert-primary {
    background-color: $primary;
    border-color: $primary;
    color: #fff
}

.alert-primary .alert-link {
    color: #fff
}

.alert-secondary {
    background-color: #dee2e6;
    border-color: #dee2e6;
    color: #1e1e1e
}

.alert-success {
    background-color: #4dd4ac;
    border-color: #4dd4ac;
    color: #fff
}

.alert-danger {
    background-color: #e35d6a;
    border-color: #e35d6a;
    color: #fff
}

.alert-warning {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #1e1e1e
}

.alert-info {
    background-color: #e6f0ff;
    border-color: #e6f0ff;
    color: #1e1e1e
}

.alert-dark {
    background-color: #1e1e1e;
    border-color: #1e1e1e;
    color: #fff
}

.alert-light {
    background-color: #fff;
    border-color: #fff;
    color: #212529
}

.form-check-input {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    margin-right: .5rem;
    cursor: pointer;
    border-color: #ced4da;
    border-radius: .25rem
}

.form-check-sm .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .2em
}

.form-check-sm label {
    font-size: .8125rem
}

.form-check-sm .form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -3.5 7 7'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-lg {
    padding-left: 2em
}

.form-check-lg .form-check-input {
    width: 2em;
    height: 2em;
    margin-left: -2em
}

.form-check-lg .form-check-input:checked[type=radio] {
    background-image: url(../../assets/svg/icons/check-light.svg);
    background-size: 1rem
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -3.5 7 7'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check {
    margin-bottom: .5rem
}

.form-check.form-check-rounded .form-check-input {
    border-radius: 50%
}

.form-check.rounded-none .form-check-input {
    border-radius: 0
}

.form-switch .form-check-input {
    border-radius: 2em;
    width: 2.688em;
    background-color: #dee2e6;
    border-color: #dee2e6;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch.form-check-sm .form-check-input {
    width: 1.813em
}

.form-switch.form-check-sm .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -3.5 7 7'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch.form-check-lg .form-check-input {
    width: 3.625em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e")
}

.form-switch.form-check-lg .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-control {
    border-color: #dee2e6;
    font-size: .875rem
}

.form-control:focus {
    box-shadow: none
}

.form-control.form-control-xl {
    height: 3.625rem;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem
}

.form-control.form-control-lg {
    font-size: 1rem
}

.form-control.form-control-sm {
    font-size: .75rem
}

textarea.form-control {
    min-height: 6.25rem
}

textarea.form-control.form-control-xl {
    height: auto;
    min-height: 6.25rem
}

textarea.form-control.form-control-lg {
    min-height: 6.25rem
}

textarea.form-control.form-control-sm {
    min-height: 6.25rem
}

.form-select {
    border-color: #dee2e6;
    font-size: .875rem;
    height: 2.375rem;
    background-image: url(../../assets/svg/icons/down-arrow.svg);
    background-repeat: no-repeat;
    background-size: 1rem .75rem;
    background-position: right .5rem center
}

.form-select:disabled {
    background-color: #ced4da;
    border-color: #ced4da
}

.form-select.form-select-lg {
    height: 3rem;
    font-size: 1rem;
    background-size: 1.125rem .875rem;
    padding-left: .75rem
}

.form-select.form-select-xl {
    height: 3.625rem;
    font-size: 1rem;
    background-size: 1.25rem 1rem
}

.form-select.form-select-sm {
    font-size: .75rem;
    height: 1.9375rem;
    background-size: 1rem .75rem
}

.form-file-label {
    font-size: .875rem;
    font-weight: 600
}

.form-file {
    height: 2.375rem
}

.form-file .form-file-label {
    height: 2.375rem
}

.form-file .form-file-label .form-file-text {
    font-size: .875rem;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center
}

.form-file .form-file-label .form-file-button {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center
}

.form-file.form-file-xl {
    height: 3.625rem
}

.form-file.form-file-xl .form-file-input {
    height: 3.625rem
}

.form-file.form-file-xl .form-file-label {
    height: 3.625rem
}

.form-file.form-file-xl .form-file-label .form-file-text {
    font-size: 1rem;
    padding: .5rem 1rem
}

.form-file.form-file-xl .form-file-label .form-file-button {
    padding: .5rem 1rem
}

.form-file.form-file-lg {
    height: 3rem
}

.form-file.form-file-lg .form-file-label {
    height: 3rem
}

.form-file.form-file-lg .form-file-label .form-file-text {
    font-size: 1rem
}

.form-file.form-file-sm {
    height: 1.9375rem
}

.form-file.form-file-sm .form-file-label {
    height: 1.9375rem;
    font-size: .625rem
}

.form-file.form-file-sm .form-file-label .form-file-text {
    font-size: .75rem
}

.input-group .form-control {
    height: 2.375rem
}

.input-group .input-group-text {
    padding: .375rem .85rem;
    font-size: .875rem;
    font-weight: 600
}

.input-group.input-group-xl .input-group-text {
    padding: .5rem 1.125rem
}

.input-group.input-group-xl .form-control {
    height: 3.625rem;
    font-size: 1rem
}

.input-group.input-group-lg .form-control {
    font-size: 1rem;
    height: 3rem
}

.input-group.input-group-lg .input-group-text {
    padding: .5rem 1rem
}

.input-group.input-group-sm .form-control {
    font-size: .75rem;
    height: 1.9375rem
}

.input-group.input-group-sm .input-group-text {
    padding: .25rem .95rem
}

.form-label {
    font-size: .875rem
}

.form-label.form-label-xl {
    font-size: 1rem
}

.form-label.form-label-lg {
    font-size: 1rem;
    font-weight: 600
}

.form-label.form-label-sm {
    font-size: .75rem
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
    padding-right: .9375rem;
    border-color: #e35d6a
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none;
    padding-right: .9375rem;
    border-color: #20c997
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff
}

.popover-header {
    line-height: 1.125rem;
    font-weight: 500
}

.transition-3d-hover {
    transition: all .2s ease-in-out
}

.transition-3d-hover:focus,
.transition-3d-hover:hover {
    -webkit-transform: translateY(-.1875rem);
    transform: translateY(-.1875rem)
}

.choices__list--multiple .choices__item {
    background-color: $primary;
    border-color: $primary
}

.choices__inner {
    min-height: 2.375rem;
    padding: .1875rem .4375rem 0;
    background-color: #fff
}

.choices__input {
    background-color: #fff
}

.overflow-remove {
    overflow: inherit !important
}

.progress-circle .progressbar-text .value {
    font-weight: 600;
    font-size: 2.98598rem;
    color: #000
}

.progress {
    background-color: #dee2e6
}

.circle {
    border-radius: 50%;
    overflow: hidden;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
}

.circle.bg-primary {
    color: #fff
}

.circle-sm {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    font-size: 1rem
}

.circle-md {
    width: 2.375rem;
    min-width: 2.375rem;
    height: 2.375rem;
    font-size: 1rem
}

.circle-lg {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    font-size: 1.5rem
}

.circle-xl {
    width: 3.625rem;
    height: 3.625rem
}

.nav-pills.tabs-has-garybg .nav-link {
    padding: 1.875rem 1.875rem 2.375rem;
    margin-bottom: 1.5625rem;
    background-color: #1a1919;
    color: #fff;
    border-radius: .75rem
}

.nav-pills.tabs-has-garybg .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.nav-pills.tabs-has-garybg .active {
    background-color: #e35d6a
}

.nav-pills.tabs-has-garybg .active .text-secondary {
    color: inherit !important
}

.nav-tabs {
    border-color: #e9ecef
}

.nav-tabs.nav-tabs-sm {
    font-size: .8125rem;
    font-weight: 600
}

.nav-tabs.nav-tabs-line {
    border-width: .1875rem;
    border-color: #e9ecef
}

.nav-tabs.nav-tabs-line .nav-link {
    border: 0;
    font-weight: 600;
    margin-bottom: -.1875rem;
    border-bottom: .1875rem solid transparent
}

.nav-tabs.nav-tabs-line .nav-link.active {
    border-color: $primary;
    color: #1e1e1e;
    background-color: transparent
}

.nav-tabs.nav-tabs-line .nav-link .status {
    width: .4375rem;
    height: .4375rem;
    top: -.75rem
}

.nav-tabs .nav-link {
    padding: .85rem 1.85rem;
    color: $secondary;
    font-family: $headings
}

.nav-tabs .nav-link.active {
    border-color: #e9ecef
}

.nav.nav-pills .nav-link {
    border-radius: 1.875rem;
    padding: .5rem 1.5rem;
    color: $secondary
}

.nav.nav-pills .nav-link.active {
    color: #fff
}

.nav-verticle {
    min-width: 11.5625rem
}

.nav-verticle .nav-link {
    color: $secondary
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 .375rem
}

.swiper-pagination {
    position: static;
    padding-top: 1.75rem
}

.swiper-pagination-bullet {
    width: .75rem;
    height: .75rem;
    background: #343a40;
    cursor: pointer;
    opacity: 1;
    transition: all ease .4s
}

.swiper-pagination-bullet-active {
    background: $primary
}

.tabler-icons li {
    padding: 1.125rem
}

.modal .btn-light {
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important
}

.modal .modal-header .btn-icon {
    height: auto
}

.modal .modal-content {
    border-radius: .25rem;
    border-color: #e9ecef
}

.modal .modal-footer {
    border-color: #e9ecef
}

.table-responsive {
    position: relative;
    z-index: 0
}

.list-group .list-group-item {
    border-color: #dee2e6
}

.list-group .list-group-item.active {
    border-color: $primary
}

.list-group .list-group-item.unread {
    background-color: #f8f9fa
}

.list-group .list-group-icon {
    margin-right: .5rem
}

.simplebar-track.simplebar-horizontal {
    display: none !important
}

.btn-close {
    background: transparent url(../../assets/svg/icons/close1.svg);
    background-repeat: no-repeat
}

.opacity-100 {
    opacity: 1
}

.end-16 {
    right: 1rem
}

.top-16 {
    top: 1rem
}

.zIndex-0 {
    z-index: 0
}

.link-underline {
    color: #6c757d;
    border-bottom: .0625rem dashed #6c757d
}

.link-underline-light {
    color: rgba(255, 255, 255, .7);
    border-bottom: .0625rem dashed rgba(255, 255, 255, .7)
}

.link-underline-light:hover {
    color: #fff;
    border-bottom: .0625rem dashed #fff
}

.link:hover .link-icon {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.link-icon {
    position: relative;
    line-height: 1;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: 1.375rem;
    height: 1.375rem;
    font-size: 1rem;
    color: #0a58ca;
    background-color: rgba(61, 139, 253, .1);
    border-radius: 50%;
    transition: .3s
}

.link-collapse[aria-expanded=false] .link-collapse-active,
.link-collapse[aria-expanded=true] .link-collapse-default {
    display: none
}

.dropzone {
    position: relative;
    background-color: #fff;
    border: 0;
    background-color: transparent;
    border-radius: .375rem
}

.dropzone .dz-preview {
    min-height: auto
}

.dropzone .dz-remove {
    position: absolute;
    right: .5rem;
    top: .5rem;
    color: #fff;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    width: 2rem;
    height: 2rem;
    line-height: 2rem
}

.dropzone .dz-remove * {
    cursor: pointer
}

.dropzone .dz-message {
    position: relative;
    padding: 5rem 2rem;
    margin: 0;
    border: .125rem dashed #d8e2ef;
    border-radius: .375rem
}

.dropzone .dz-preview-single {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0
}

.dropzone .dz-preview-single:hover {
    z-index: auto
}

.dropzone .dz-preview-single .dz-preview-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0 !important;
    overflow: hidden
}

.dropzone .dz-preview-single .dz-preview-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: .375rem
}

.dropzone .dz-progress {
    background: #edf2f9 !important
}

.dropzone .dz-progress .dz-upload {
    background: #2c7be5 !important
}

.dropzone.dropzone-has-default .dz-message {
    border-color: transparent;
    transition: all .2s ease-in-out
}

.dropzone.dropzone-has-default .dz-message:hover {
    background-color: rgba(0, 0, 0, .5)
}

.dropzone.dropzone-single.dz-file-processing .dz-message {
    display: none
}

.dropzone.dropzone-single.dz-file-processing.dz-file-complete .dz-message {
    display: block
}

.dropzone.dropzone-single.dz-file-processing.dz-file-complete .dz-default-image {
    opacity: 0
}

.dropzone.dropzone-single .dz-processing .dz-message-text {
    opacity: 0 !important
}

.dropzone.dropzone-single .dz-processing .dz-progress {
    opacity: 1
}

.dropzone.dropzone-single .dz-progress {
    opacity: 1;
    transition: opacity .4s ease-in;
    -webkit-animation: none !important;
    animation: none !important;
    width: 9.375rem !important;
    height: .5rem !important;
    margin: 0 !important;
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    top: auto !important;
    bottom: 1.5rem
}

.dropzone.dropzone-single .dz-complete .dz-progress {
    opacity: 0
}

.dropzone.dropzone-single.dz-max-files-reached .dz-message {
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    opacity: 0;
    transition: all .2s ease-in-out;
    border-color: transparent
}

.dropzone.dropzone-single.dz-max-files-reached .dz-remove {
    opacity: 1
}

.dropzone.dropzone-single.dz-max-files-reached:hover .dz-message {
    opacity: 1
}

.dropzone.dropzone-single.dz-max-files-reached:focus .dz-message {
    opacity: 1
}

.dropzone.dropzone-multiple {
    border: 0;
    background: 0 0
}

.dropzone.dropzone-multiple .dz-message {
    padding: 4rem 2rem;
    border: .125rem dashed #d8e2ef;
    border-radius: .375rem
}

.dropzone.dropzone-multiple .btn * {
    cursor: pointer
}

.dropzone.dropzone-multiple .media:first-child {
    margin-top: 1.8rem
}

.dropzone.dropzone-multiple .dz-image {
    border-radius: .25rem;
    height: 2.5rem;
    width: 2.5rem;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 1rem;
    border: .0625rem solid #d8e2ef
}

.dropzone.dropzone-multiple .dz-progress {
    opacity: 0;
    position: relative !important;
    transition: opacity .4s ease-in;
    -webkit-animation: none !important;
    animation: none !important;
    width: 6.25rem !important;
    height: .5rem !important;
    margin-top: 0;
    margin-left: 1rem !important;
    -webkit-transform: none !important;
    transform: none !important;
    top: auto !important;
    left: auto !important
}

.dropzone.dropzone-multiple .dz-complete .dz-progress {
    transition-delay: 1s;
    opacity: 1
}

.dropzone.dz-drag-hover .dz-message {
    border-color: #2c7be5
}

.dropzone-area {
    border: .125rem dashed #d8e2ef;
    border-radius: .25rem;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer
}

.flex-1 {
    -ms-flex: 1;
    flex: 1
}

.dz-preview .dropdown-toggle::after {
    display: none
}

.dz-preview .btn-link {
    color: #6c757d
}

@media (max-width:767px) {
    .avatar.avatar-xxl {
        height: 5.625rem;
        min-width: 5.625rem;
        width: 5.625rem
    }

    .avatar.avatar-xl {
        height: 4.875rem;
        min-width: 4.875rem;
        width: 4.875rem
    }

    .h2,
    h2 {
        line-height: 2rem
    }

    .btn-group-lg>.btn,
    .btn-lg {
        height: 2.625rem;
        font-size: .9375rem
    }

    .btn-group-xl>.btn,
    .btn-xl {
        height: 3.25rem;
        padding: .375rem 1.375rem;
        font-size: 1.125rem;
        line-height: 2rem
    }

    .nav-tabs.nav-tabs-line {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: .625rem;
        border-bottom: none
    }

    .nav-tabs.nav-tabs-line .nav-link {
        white-space: nowrap;
        margin-bottom: 0;
        border-color: #e9ecef
    }
}

.header-fixed {
    position: fixed;
    left: 16.0625rem;
    top: 0;
    right: 0;
    background-color: #f8f9fa;
    z-index: 16
}

.header-search {
    min-width: 20rem
}

.header-search .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin: 0
}

.notification {
    position: relative
}

.notification .status {
    top: -.9375rem;
    right: .4375rem
}

.header .dropdown-menu {
    margin-top: .875rem !important
}

.header .customize-btn {
    display: none
}

.double-header-nav,
.top-header-nav,
.white-logo {
    display: none
}

.top-header .top-header-nav {
    display: block
}

.double-header .navbar-vertical.navbar-expand-lg,
.top-header .navbar-vertical.navbar-expand-lg {
    display: none
}

.double-header .main-content,
.top-header .main-content {
    margin-left: 0
}

.double-header .header,
.top-header .header {
    background-color: #fff
}

.double-header .header .customize-btn,
.top-header .header .customize-btn {
    display: block
}

.double-header .header .customize-btn svg path,
.top-header .header .customize-btn svg path {
    fill: #fff
}

.double-header .header-fixed,
.top-header .header-fixed {
    left: 0
}

.double-header .navbar-brand.d-lg-none,
.top-header .navbar-brand.d-lg-none {
    display: block !important
}

.double-header .back-arrow,
.double-header .header-search,
.top-header .back-arrow,
.top-header .header-search {
    display: none
}

.double-header .navbar,
.top-header .navbar {
    padding: 0;
    border-left: .0625rem solid #e9ecef;
    padding-left: .625rem;
    margin-left: 1.5rem
}

.double-header .navbar .nav-item,
.top-header .navbar .nav-item {
    position: relative
}

.double-header .navbar .nav-item .nav-link,
.top-header .navbar .nav-item .nav-link {
    font-weight: 600;
    font-size: .875rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .6875rem 1.375rem
}

.double-header .navbar .nav-item .nav-link[data-bs-toggle=collapse]:after,
.top-header .navbar .nav-item .nav-link[data-bs-toggle=collapse]:after {
    display: block;
    content: url(../../assets/svg/icons/chevron-down1.svg);
    margin-left: .8125rem;
    width: .75rem;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s
}

.double-header .navbar .nav-item .nav-link[aria-expanded=true]:after,
.top-header .navbar .nav-item .nav-link[aria-expanded=true]:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.double-header .navbar .nav-item .nav-link svg path,
.top-header .navbar .nav-item .nav-link svg path {
    fill: #6c757d
}

.double-header .navbar .nav-item .nav-link .docs-version,
.top-header .navbar .nav-item .nav-link .docs-version {
    border: .0625rem solid #6c757d;
    border-radius: .25rem;
    font-size: .6875rem;
    padding: 0 .25rem;
    line-height: .875rem;
    margin-left: .375rem
}

.double-header .navbar .nav-item .nav-link:hover,
.double-header .navbar .nav-item .nav-link[aria-expanded=true],
.top-header .navbar .nav-item .nav-link:hover,
.top-header .navbar .nav-item .nav-link[aria-expanded=true] {
    color: #1e1e1e
}

.double-header .navbar .nav-item .nav-link:hover svg path,
.double-header .navbar .nav-item .nav-link[aria-expanded=true] svg path,
.top-header .navbar .nav-item .nav-link:hover svg path,
.top-header .navbar .nav-item .nav-link[aria-expanded=true] svg path {
    fill: #1e1e1e
}

.double-header .navbar .nav-item .collapse-box,
.top-header .navbar .nav-item .collapse-box {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    min-width: 13.125rem;
    background-color: #fff;
    border-radius: .375rem;
    box-shadow: 0 .0625rem .5625rem rgba(0, 0, 0, .08)
}

.double-header .navbar .nav-item .collapse-box .nav,
.top-header .navbar .nav-item .collapse-box .nav {
    padding: .5rem 0
}

.double-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link,
.top-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link {
    padding: .5rem 1.375rem
}

.double-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link:after,
.top-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link:after {
    margin-left: auto
}

.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box,
.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box {
    position: static;
    padding: 0 0 0 .625rem;
    box-shadow: none
}

.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link,
.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link {
    position: relative;
    font-weight: 400;
    padding: .3125rem 1.375rem
}

.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link:before,
.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link:before {
    content: '';
    width: .1875rem;
    height: .1875rem;
    border-radius: 50%;
    background-color: #6c757d;
    display: inline-block;
    vertical-align: top;
    margin-right: .4375rem
}

.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link.active,
.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link.active {
    font-weight: 600
}

.double-header footer .navbar,
.top-header footer .navbar {
    border: none;
    padding-left: 0;
    margin-left: 0
}

.double-header .navbar-brand {
    padding: 0
}

.double-header .navbar-brand img {
    display: none
}

.double-header .navbar-brand .white-logo {
    display: inline-block
}

.double-header .double-header-nav {
    display: block;
    padding: .6875rem .9375rem
}

.double-header .header-body {
    background-color: #1a1919
}

.double-header .header-body .customize-btn {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
    color: #1e1e1e
}

.double-header .header-body .customize-btn svg path {
    fill: #1e1e1e
}

.double-header .header-body .customize-btn:hover {
    background-color: #ffcd39 !important;
    border-color: #ffcd39 !important
}

.double-header .grid-option>a svg path {
    fill: #fff
}

.double-header .navbar {
    border-left: none;
    margin-left: 0
}

.double-header .main-content {
    margin-top: 10.125rem
}

.double-header .chat-items {
    height: calc(100vh - 27.25rem)
}

.double-header .chat-list {
    height: calc(100vh - 16.3rem)
}

.navbar-toggler:focus {
    box-shadow: none
}

.navbar-vertical.navbar-expand-lg {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    width: 100%;
    height: 100%;
    max-width: 16.0625rem;
    padding-left: 2rem;
    border-right: .0625rem solid #e9ecef;
    padding-right: 2rem
}

.navbar-vertical.navbar-expand-lg .navbar-collapse {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: auto;
    min-width: 100%;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow-y: auto
}

.navbar-vertical.navbar-expand-lg .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 15rem);
    margin-left: -2rem;
    margin-right: -2rem
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
    padding: 0;
    margin-top: .625rem
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle .small,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle small {
    font-family: $headings;
    font-size: .625rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .075rem;
    color: #adb5bd;
    display: block;
    padding: 0 2rem
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .775rem 2rem;
    font-weight: 600;
    color: #6c757d;
    font-family: $headings;
    font-size: .875rem
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link svg path {
    fill: #6c757d;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[aria-expanded=true] {
    color: $primary;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active svg path,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover svg path,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[aria-expanded=true] svg path {
    fill: $primary;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active svg polygon,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover svg polygon,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[aria-expanded=true] svg polygon {
    fill: $primary;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .docs-version {
    border-radius: .25rem;
    font-size: .6875rem;
    font-weight: 600;
    padding: 0 .5rem;
    border: .0625rem solid #6c757d;
    color: #6c757d;
    margin-left: .5rem
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item {
    margin-top: 0
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
    padding-left: 3.8rem;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link {
    padding-left: 4.8rem;
    font-weight: 500
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link:hover:before {
    background-color: #1e1e1e
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link.active {
    font-weight: 600
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link:before {
    content: '';
    width: .1875rem;
    height: .1875rem;
    border-radius: 50%;
    background-color: #6c757d;
    display: inline-block;
    vertical-align: top;
    margin-right: .4375rem
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
    display: block;
    content: url(../../assets/svg/icons/chevron-down1.svg);
    margin-left: auto;
    width: .75rem;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.navbar-vertical .navbar-vertical-footer-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 0;
    margin-bottom: 0
}

.navbar-vertical .navbar-vertical-footer-list>li {
    list-style: none;
    padding: .25rem 0
}

.navbar-vertical .navbar-vertical-footer-list>li .dropdown-menu {
    min-width: 8.875rem
}

.navbar-vertical .navbar-vertical-footer-list>li a:hover svg path {
    fill: #1e1e1e
}

.navbar-vertical .navbar-vertical-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: .625rem 2.125rem;
    background-color: #fff
}

.nav-sm .nav-link {
    font-size: .8125rem
}

.navbar-light {
    background-color: #fff
}

.sidebar-compact .header-fixed,
.sidebar-icons .header-fixed {
    left: 5rem
}

nav.navbar-vertical.navbar-expand-lg, .main-content .header, .main-content {
    transition: all .3s ease-out;
}

.sidebar-compact .navbar-vertical.navbar-expand-lg,
.sidebar-icons .navbar-vertical.navbar-expand-lg {
    max-width: 5rem;
    padding: .5rem 0;
    transition: -webkit-transform .3s ease-out;
    transition: all .3s ease-out;
    transition: all .3s ease-out, -webkit-transform .3s ease-out;
    overflow: visible;
    text-align: center;
    z-index: 2
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-collapse,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-collapse {
    padding: 0;
    margin: 0
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .simplebar-content>li:hover>.collapse-box,
.sidebar-icons .navbar-vertical.navbar-expand-lg .simplebar-content>li:hover>.collapse-box {
    display: block !important;
    height: auto !important
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .simplebar-content>li:hover>.collapse-box>.collapse-box:not(.show),
.sidebar-icons .navbar-vertical.navbar-expand-lg .simplebar-content>li:hover>.collapse-box>.collapse-box:not(.show) {
    display: none
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .simplebar-content>li>.collapse-box,
.sidebar-icons .navbar-vertical.navbar-expand-lg .simplebar-content>li>.collapse-box {
    display: none
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav {
    margin: 0;
    height: calc(100vh - 10rem)
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
    position: static;
    margin-top: 1.875rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link {
    font-size: 0;
    position: relative
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link .ms-2,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link .ms-2 {
    margin-left: 0 !important
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle {
    margin-top: 0
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle .small,
.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle small,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle .small,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle small {
    font-size: 0
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    position: fixed;
    top: inherit;
    left: 5rem;
    min-width: 9.375rem;
    background-color: #fff;
    border-radius: .375rem;
    box-shadow: 0 .0625rem .5625rem rgba(0, 0, 0, .08);
    z-index: 99;
    padding: .5rem 0
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box>.nav,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box>.nav {
    min-width: 13.125rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .nav-item,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .nav-item {
    margin-top: 0
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .nav-link,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .nav-link {
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: .5rem .9375rem;
    font-size: .875rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .nav-link[data-bs-toggle=collapse]:after,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .nav-link[data-bs-toggle=collapse]:after {
    display: block
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box {
    position: static;
    box-shadow: none;
    border-radius: 0;
    padding: 0
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box .nav-link,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box .nav-link {
    padding-left: 1.5625rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    padding: .625rem;
    -ms-flex-pack: center;
    justify-content: center
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .status,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .status {
    top: -1rem;
    left: .375rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link>span.docs-version,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link>span.docs-version {
    position: absolute;
    top: 100%;
    left: 0;
    width: 2.375rem;
    right: 0;
    margin: 0 auto
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[data-bs-toggle=collapse]:after,
.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
    display: none
}

.sidebar-compact .navbar-vertical .navbar-vertical-footer,
.sidebar-icons .navbar-vertical .navbar-vertical-footer {
    display: none
}

.sidebar-compact .signout,
.sidebar-icons .signout {
    display: inline-block !important
}

.sidebar-compact .signout img,
.sidebar-icons .signout img {
    max-width: 1.25rem
}

.sidebar-compact .signout .btn-group-lg>.btn,
.sidebar-compact .signout .btn-lg,
.sidebar-icons .signout .btn-group-lg>.btn,
.sidebar-icons .signout .btn-lg {
    padding: .5rem .8125rem
}

.sidebar-compact .signout .btn-block,
.sidebar-icons .signout .btn-block {
    width: auto
}

.sidebar-compact .signout img,
.sidebar-icons .signout img {
    display: block
}

.sidebar-compact .signout span,
.sidebar-icons .signout span {
    display: none
}

.sidebar-compact .main-content,
.sidebar-icons .main-content {
    margin-left: 5rem;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out
}

.sidebar-compact .navbar-brand img,
.sidebar-icons .navbar-brand img {
    display: none
}

.sidebar-compact .navbar-brand .muze-icon,
.sidebar-icons .navbar-brand .muze-icon {
    display: block;
    margin: 0 auto
}

.sidebar-compact .back-arrow img,
.sidebar-compact .back-arrow svg,
.sidebar-icons .back-arrow img,
.sidebar-icons .back-arrow svg {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.sidebar-compact .header-fixed {
    left: 9.0625rem
}

.sidebar-compact .navbar-brand img {
    display: none
}

.sidebar-compact .navbar-brand .muze-icon {
    display: inline-block
}

.sidebar-compact .navbar-vertical.navbar-expand-lg {
    max-width: 9.0625rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
    margin-top: 1.375rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link {
    font-size: 0;
    display: block
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link span:not(.docs-version) {
    font-size: .875rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link img,
.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link svg {
    display: block;
    margin: 0 auto .5rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link .status {
    left: auto;
    top: -.375rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    left: 9.0625rem;
    text-align: left
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item .nav .nav-item>.nav-link {
    display: -ms-flexbox;
    display: flex
}

.sidebar-compact .main-content {
    margin-left: 9.0625rem
}

.sidebar-compact .signout .btn-group-lg>.btn,
.sidebar-compact .signout .btn-lg {
    padding: .625rem 1.125rem;
    height: 3.625rem
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav {
    height: calc(100vh - 10.9375rem)
}

.customize-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    background-color: #f8f9fa;
    width: 29.6875rem;
    height: 100vh;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: all ease .4s
}

.customize-sidebar .btn-light:hover {
    background-color: #cfe2ff !important;
    border-color: #cfe2ff !important
}

.customize-sidebar .btn-light:hover svg path {
    fill: $primary
}

.customize-body {
    height: calc(100vh - 16.5625rem)
}

.customizer-controls .pe-4 {
    padding-right: 0 !important
}

.customize-box {
    overflow: hidden
}

.customize-box:before {
    content: '';
    background-color: rgba(26, 25, 25, .3);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 17
}

.customize-box .customize-sidebar {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.sidebar-style .form-check {
    position: relative;
    padding-left: 0
}

.sidebar-style .form-check-input {
    position: absolute;
    bottom: 3px;
    z-index: 0;
    left: 1.5em
}

.sidebar-style .form-check-input:checked~.form-check-label img.border-gray-400 {
    border-color: $primary !important
}

.sidebar-style .form-check-label img {
    max-width: 6.25rem
}

.sidebar-style .form-check-label span {
    padding-left: 1.5em
}

.dark-radio .form-check-input {
    background-color: #343a40;
    border-color: #6c757d
}

.dark-radio .form-check-input:checked {
    background-color: $primary;
    border-color: $primary
}

.link-dark:hover {
    color: $primary
}

.link-dark:hover svg path {
    fill: $primary
}

.signout img {
    max-width: .875rem
}

.main-content {
    margin-left: 16.0625rem;
    margin-top: 6.0625rem
}

.small-title {
    display: block;
    font-size: .75rem;
    text-transform: uppercase;
    color: #adb5bd;
    font-weight: 600;
    margin-bottom: .125rem
}

.modal-close {
    margin-right: -.625rem
}

.form-select-sm {
    font-weight: 600;
    padding-right: 1.375rem;
    background-position: right .3rem center
}

.card {
    border: none
}

.card-header {
    border-color: rgba(108, 117, 125, .125);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    height: 3.75rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: #fff
}

.list-group .list-group-item {
    padding: .75rem .125rem;
    border-color: #e9ecef
}

.list-group .list-group-item .h6 a,
.list-group .list-group-item h6 a {
    color: $secondary
}

.list-group .list-group-item .h6 a:hover,
.list-group .list-group-item h6 a:hover {
    color: $primary
}

.dropdown-ellipses {
    color: #adb5bd
}

.dropdown-ellipses:hover {
    color: $primary
}

.dropdown-ellipses:after {
    display: none
}

.dropdown-ellipses .fas {
    font-size: .8125rem
}

.dropdown-menu {
    border: none;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .16);
    border-radius: .75rem;
    padding: .5rem 0;
    color: #6c757d;
    min-width: 12.1875rem;
    z-index: 6;
    margin-top: .3125rem !important
}

.dropdown-menu .dropdown-body {
    max-height: 23.4375rem;
    overflow-y: auto
}

.dropdown-menu .dropdown-item {
    font-size: .8125rem;
    font-weight: 600;
    padding: .625rem 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
}

.dropdown-menu .dropdown-item:not(.active):not(:active) {
    color: #6c757d;
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:hover {
    background-color: #e9ecef;
    color: #1e1e1e !important
}

.dropdown-menu .dropdown-item.active svg path,
.dropdown-menu .dropdown-item:active svg path,
.dropdown-menu .dropdown-item:hover svg path {
    fill: #1e1e1e
}

.dropdown-menu .dropdown-item.unread {
    background-color: #f8f9fa
}

.dropdown-menu .dropdown-item.unread .dropdown-title {
    color: #1e1e1e
}

.dropdown-menu .dropdown-item.unread .dropdown-content {
    color: #6c757d;
    font-weight: 600
}

.dropdown-menu .dropdown-item .dropdown-content {
    font-weight: 400
}

.dropdown-menu .dropdown-divider {
    border-color: #dee2e6;
    opacity: 1
}

.dropdown-menu .dropdown-sub-title {
    font-size: .6875rem;
    padding: .5rem 1rem .3125rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #adb5bd
}

.dropdown-menu .nav-tabs-line .nav-item {
    width: 50%;
    text-align: center
}

.profile-dropdown>.dropdown-menu {
    min-width: 15.5625rem
}

.profile-dropdown>.dropdown-menu .dropdown-menu {
    min-width: 10rem;
    right: 100%;
    margin-right: .375rem;
    top: 0
}

.profile-dropdown .dropdown-item {
    padding: .75rem 1.5rem
}

.grid-option>.dropdown-menu {
    min-width: 23.625rem
}

.grid-option .dropdown-body .dropdown-item {
    border-top: .0625rem solid #e9ecef;
    padding: 1rem 1.5rem
}

.export-dropdown .dropdown-menu .dropdown-sub-title {
    color: $secondary;
    padding: .5rem 1.5rem
}

.export-dropdown .dropdown-menu .dropdown-item {
    padding: .625rem 1.5rem
}

.export-dropdown .btn svg {
    transition: all ease .4s
}

.export-dropdown .btn[aria-expanded=true] svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.get-startedtwo {
    position: absolute;
    right: 1.875rem;
    bottom: -1.5625rem;
    z-index: -1
}

.card-table {
    margin-bottom: 0;
    color: $secondary
}

.card-table td {
    font-size: 1rem;
    padding: .9375rem 2rem;
    vertical-align: middle;
    border-color: #e9ecef !important;
    color: #1e1e1e
}

.card-table th {
    font-size: .8125rem;
    text-transform: uppercase;
    font-weight: 400;
    color: #6c757d;
    padding: .8125rem 2rem;
    background-color: #f8f9fa;
    border-color: #f8f9fa !important
}

.card-table th:last-child {
    width: 6.875rem
}

.card-table tr:last-child td {
    border-bottom: 0
}

.card-table.table [data-sort],
.card-table.table-nowrap td,
.card-table.table-nowrap th {
    white-space: nowrap
}

.check-list .form-check-input:checked~.form-check-label {
    text-decoration: line-through;
    color: #adb5bd
}

.check-list .form-check-label {
    color: $secondary
}

.list-group-activity .list-group-item {
    border-bottom: none;
    position: relative;
    z-index: 1
}

.list-group-activity .list-group-item:first-child .col-auto:after {
    top: 1.875rem
}

.list-group-activity .list-group-item:last-child .col-auto:after {
    height: 50%
}

.list-group-activity .list-group-item .col-auto:after {
    content: '';
    display: block;
    height: 100%;
    width: .0625rem;
    background-color: #ced4da;
    position: absolute;
    left: 1.4375rem;
    top: 0;
    z-index: -1
}

.badge-sm {
    padding: .3125rem .625rem;
    font-size: .6em
}

.form-check-sm .form-check-input {
    margin-top: .25em
}

.form-check-sm .form-check-label {
    font-size: 90%
}

.checklist-box textarea.form-control {
    resize: none;
    overflow: hidden;
    overflow-wrap: break-word;
    height: 1.5625rem;
    min-height: auto;
    padding-left: 0;
    border: none;
    font-size: 1rem;
    color: $secondary
}

.checklist-box textarea.form-control::-webkit-input-placeholder {
    color: $secondary
}

.checklist-box textarea.form-control::-moz-placeholder {
    color: $secondary
}

.checklist-box textarea.form-control:-ms-input-placeholder {
    color: $secondary
}

.checklist-box textarea.form-control:-moz-placeholder {
    color: $secondary
}

.checklist-box textarea.form-control:focus {
    box-shadow: none
}

.muze-icon {
    display: none;
    width: 80px;
}

.nav-tabs-sm .nav-link {
    font-size: .8125rem
}

.card-header-tabs {
    border-bottom: none;
    margin: -.75rem 0
}

.card-header-tabs .nav-item {
    padding: 0 .5rem
}

.card-header-tabs .nav-link {
    border: none;
    padding: 1.25rem 0;
    color: #adb5bd;
    font-weight: 500
}

.card-header-tabs .nav-link.active {
    border-bottom: .0625rem solid $primary;
    color: #000
}

.btn-link.link-primary:hover {
    color: #212529
}

.btn-link.link-primary:hover svg path {
    fill: #212529
}

.get-started-img {
    position: absolute;
    bottom: -19.375rem;
    right: 3.125rem;
    z-index: -1
}

.get-started-two {
    bottom: -1.875rem
}

footer .navbar {
    -ms-flex-pack: end;
    justify-content: flex-end;
    border: none
}

footer .navbar li {
    padding: .3125rem 0 .3125rem 3.125rem
}

footer .navbar li a {
    color: #6c757d;
    font-size: 1rem
}

footer .navbar li a:hover {
    color: $primary
}

.get-startedthree {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -18.4375rem
}

.project-management-template table td .progress {
    width: 10.9375rem;
    margin-left: .375rem
}

.title-box {
    min-height: 3.125rem
}

.sidebar-nav {
    padding-left: 0;
    margin-bottom: .5rem
}

.sidebar-nav li {
    list-style: none;
    border-top: .0625rem solid #e9ecef
}

.sidebar-nav li a {
    font-weight: 600;
    color: #adb5bd;
    padding: .8125rem 1.25rem;
    display: block;
    font-size: .875rem;
    border-left: .25rem solid transparent
}

.sidebar-nav li a.active,
.sidebar-nav li a:hover {
    color: #1e1e1e;
    border-left-color: $primary;
    background-color: #f8f9fa
}

.sidebar-nav li a.active svg path,
.sidebar-nav li a:hover svg path {
    fill: #1e1e1e
}

.sidebar-nav li a svg path {
    fill: #adb5bd;
    transition: all ease .4s
}

.form-style-two .form-control {
    color: #6c757d
}

.form-style-two ul {
    padding-left: 0
}

.form-style-two ul li {
    font-size: .8125rem;
    color: #343a40;
    list-style: none;
    margin-bottom: .25rem
}

.invoice-table tr {
    vertical-align: middle
}

.invoice-table tr:last-child td {
    border: none
}

.invoice-table td {
    padding: 1.5625rem .625rem;
    white-space: nowrap
}

.invoice-table td:first-child {
    padding-left: 0
}

.invoice-table td:last-child {
    padding-right: 0
}

.invoice-template .card-table td {
    padding: 1.375rem 2rem
}

.invoice-template .card-table td:last-child {
    text-align: right
}

.signup-template .cover-fit {
    height: 100vh;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.login-form {
    max-width: 32.9375rem
}

.login-form a:focus,
.login-form a:hover,
.signup-simple-template a:focus,
.signup-simple-template a:hover,
.simple-login-form a:focus,
.simple-login-form a:hover {
    color: $primary !important
}

.login-form .form-check-label,
.signup-simple-template .form-check-label,
.simple-login-form .form-check-label {
    margin-top: .125rem
}

.signup-header {
    padding: 4rem 1.875rem 16.625rem
}

.simple-login-form {
    width: 30.75rem;
    margin: -15rem auto 5.4375rem;
    padding: 3.5rem
}

.user-profile-template a:not(.btn):focus,
.user-profile-template a:not(.btn):hover {
    color: $primary
}

.user-profile-template .avatar-xs {
    width: 2rem;
    height: 2rem;
    min-width: 2rem
}

.user-profile-template .list-group .list-group-item {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.user-profile-template .tiny {
    font-size: .625rem !important
}

.user-profile-template .get-startedtwo {
    bottom: -6.25rem;
    right: -8.4375rem
}

.profile-list {
    margin-top: 1rem;
    margin-bottom: 0
}

.profile-list li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .5rem 0
}

.profile-list li span+span {
    width: calc(100% - 2rem);
    padding-left: .75rem;
    color: $secondary
}

.group-cards .avatar.avatar-lg {
    width: 3.5rem;
    min-width: 3.5rem;
    height: 3.5rem
}

.group-cards .title-box {
    min-height: 5.625rem
}

.pricing-template .top-less {
    margin-top: -15.625rem
}

.pricing-template .badge.position-absolute.end-0 {
    right: 1rem !important
}

.pricing-template .badge.position-absolute.top-0 {
    top: 1rem !important
}

.pricing-template .nav-segment {
    padding: .375rem
}

.pricing-template .nav-segment.nav-pills .nav-link {
    padding: .4rem 1.2rem;
    color: #fff;
    border-radius: 1.25rem
}

.pricing-template .nav-segment.nav-pills .nav-link.active {
    background-color: #ffc107;
    color: #1e1e1e
}

.pricing-template .nav-segment.nav-pills .show>.nav-link {
    background-color: #ffc107;
    color: #1e1e1e
}

.muze-collapes .card {
    background-color: transparent;
    border: none;
    border-bottom: .0625rem solid #e9ecef !important;
    padding: .5rem 0;
    border-radius: 0
}

.muze-collapes .card .card-header {
    background-color: transparent;
    border: none;
    margin-bottom: 0;
    height: auto
}

.muze-collapes .card .card-header .h5,
.muze-collapes .card .card-header h5 {
    width: 100%
}

.muze-collapes .card .card-header .btn-light {
    background-color: transparent !important;
    border: none !important;
    padding: .75rem 2rem .75rem 0 !important;
    font-size: 1.25rem;
    font-weight: 600;
    box-shadow: none;
    position: relative
}

.muze-collapes .card .card-header .btn-light[aria-expanded=true]:before {
    display: none
}

.muze-collapes .card .card-header .btn-light:after {
    content: '';
    height: .125rem;
    width: 1.25rem;
    background-color: #1e1e1e;
    border-radius: .625rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0
}

.muze-collapes .card .card-header .btn-light:before {
    content: '';
    height: 1.25rem;
    width: .125rem;
    background-color: #1e1e1e;
    border-radius: .625rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: .5625rem
}

.plan-list li {
    line-height: 1.75rem;
    color: $secondary
}

.plan-list li+li {
    margin-top: .9375rem
}

.plan-list li span {
    padding-left: .8125rem
}

.search-less {
    margin-top: -2.5rem
}

.search-left {
    width: 100%
}

.all-projects-template a:not(.btn):focus,
.all-projects-template a:not(.btn):hover {
    color: $primary
}

.all-projects-template .nav-pills .nav-link {
    border-radius: 1.875rem;
    font-size: .9375rem;
    color: $secondary
}

.all-projects-template .nav-pills .nav-link.active {
    font-weight: 600;
    color: #fff;
    background-color: $primary
}

.new-projects-template .form-label {
    color: #1e1e1e
}

.new-projects-template form .circle-xl {
    width: 6.25rem;
    height: 6.25rem
}

.ql-container.ql-snow {
    border-color: #e9ecef;
    border-radius: 0 0 .25rem .25rem;
    height: 18.125rem;
    font-size: 1rem;
    color: #1e1e1e;
    font-style: normal;
    font-family: inherit
}

.ql-toolbar.ql-snow {
    border-color: #e9ecef;
    border-radius: .25rem .25rem 0 0
}

.ql-editor.ql-blank:before {
    font-style: normal
}

.step-list {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0
}

.step-list .circle {
    margin: 0 auto
}

.step-list li {
    position: relative;
    list-style: none;
    width: 33.33%;
    text-align: center
}

.step-list li:not(:first-child):before {
    content: '';
    height: .125rem;
    width: 100%;
    background-color: #e9ecef;
    position: absolute;
    left: -50%;
    top: 1.5625rem;
    z-index: -1
}

.step-list li.active:before {
    background-color: $primary
}

.activity-list .list-group-item {
    border: none;
    padding-top: 0;
    padding-bottom: 2.5rem;
    position: relative
}

.activity-list .list-group-item:not(:last-child):before {
    content: '';
    width: .0625rem;
    height: 100%;
    background-color: #e9ecef;
    position: absolute;
    z-index: 0;
    top: 2.8125rem;
    left: 4.0625rem
}

.fc .fc-view-harness {
    z-index: 0
}

.calendar-template .calendar-box a {
    color: #343a40;
    font-weight: 400
}

.calendar-template .fc .fc-button-primary {
    background-color: $primary;
    border-color: $primary
}

.calendar-template .fc .fc-button-primary:not(:disabled).fc-button-active,
.calendar-template .fc .fc-button-primary:not(:disabled):active,
.calendar-template .fc .fc-button-primary:not(:disabled):hover {
    background-color: #0a58ca;
    border-color: #0a58ca
}

.calendar-template .fc .fc-today-button {
    background-color: #9ec5fe;
    border-color: #9ec5fe
}

.calendar-template .fc .fc-toolbar-title {
    font-weight: 600
}

.file-manager-template .dropdown.top-0 {
    top: .5rem !important
}

.file-manager-template .dropdown.end-0 {
    right: .5rem !important
}

.file-manager-template .btn-group-sm>.btn-icon.btn,
.file-manager-template .btn-icon.btn-sm {
    width: 1.5rem;
    height: 1.5rem
}

.chat-header a.circle {
    background-color: #e9ecef
}

.chat-header a.circle:hover {
    background-color: $primary
}

.chat-header a.circle:hover svg path {
    fill: #fff
}

.opacity-1 {
    opacity: 1
}

.chat-list {
    height: calc(100vh - 12.125rem)
}

.chat-list a {
    padding: 1.625rem 2.5rem;
    display: block;
    color: #1e1e1e;
    border-left: .3125rem solid transparent
}

.chat-list a.active {
    border-color: $primary;
    background-color: #e6f0ff
}

.chat-list a.active .h6,
.chat-list a.active h6 {
    font-weight: 600
}

.chat-list a.unread {
    background-color: #f8f9fa
}

.chat-list a.unread .h6,
.chat-list a.unread h6,
.chat-list a.unread p {
    font-weight: 600;
    color: #1e1e1e
}

.chat-list a.unread .unread-status {
    display: block
}

.chat-list a .unread-status {
    display: none;
    position: absolute;
    right: 0;
    bottom: .625rem;
    z-index: 0
}

.chat-list a .h6,
.chat-list a h6 {
    font-weight: 400;
    font-family: $content;
    margin-bottom: .25rem
}

.chat-list a p {
    font-size: .8125rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #6c757d;
    margin-bottom: 0;
    padding-right: 3.4375rem
}

.chat-list .media .media-body {
    width: calc(100% - 4.125rem);
    position: relative
}

.chat-item {
    padding: 1.5rem 0;
    max-width: 48.125rem
}

.chat-item-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.chat-message {
    padding-left: 1.5625rem;
    padding-top: .625rem
}

.message-box {
    background-color: #f8f9fa;
    padding: 1.125rem 2rem;
    border-radius: .5rem;
    position: relative
}

.message-box:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 1.125rem solid transparent;
    border-right: 1.25rem solid #f8f9fa;
    position: absolute;
    bottom: 30%;
    left: -1.125rem
}

.message-box p {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 2;
    color: #343a40
}

.message-files {
    padding-left: 2.1875rem
}

.message-files ul {
    padding-left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: .625rem -.375rem
}

.message-files ul li {
    list-style: none;
    padding: 0 .375rem;
    width: 18%
}

.message-sender {
    padding-left: 2.5rem
}

.message-sender .message-box {
    background-color: $primary
}

.message-sender .message-box:before {
    border-right: 1.25rem solid $primary
}

.message-sender .message-box p {
    color: #fff
}

.message-sender .message-files {
    padding-left: 4.6875rem
}

.attachment {
    background-color: #e9ecef;
    border-radius: 1.5rem;
    padding: .75rem 1.5rem;
    margin: .375rem .375rem .375rem 0;
    display: inline-block;
    color: #343a40
}

.chat-items {
    height: calc(100vh - 23.25rem)
}

.message-footer .form-control {
    font-size: 1rem;
    border: none;
    resize: none;
    padding-left: 0;
    min-height: 3.125rem
}

.message-footer a:not(.btn) {
    margin-right: 2.5rem
}

.message-footer a:not(.btn):hover svg path {
    fill: $primary
}

.muze-icon-white,
.white-logo2 {
    display: none
}

.muze-dark-mode {
    color: #adb5bd
}

.muze-dark-mode.bg-gray-100 {
    background-color: #1e1e1e !important
}

.muze-dark-mode .apexcharts-gridline {
    stroke: #343a40
}

.muze-dark-mode .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: #343a40
}

.muze-dark-mode .simplebar-scrollbar:before {
    background-color: #ced4da
}

.muze-dark-mode .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1
}

.muze-dark-mode .form-control {
    color: #e9ecef
}

.muze-dark-mode .progress {
    background-color: #343a40
}

.muze-dark-mode .navbar-vertical-footer-list>li:not(.dropup) a img {
    -webkit-filter: brightness(6);
    filter: brightness(6)
}

.muze-dark-mode.sidebar-compact .navbar-vertical .navbar-brand img,
.muze-dark-mode.sidebar-icons .navbar-vertical .navbar-brand img {
    display: none
}

.muze-dark-mode.sidebar-compact .navbar-vertical .navbar-brand img.white-logo2,
.muze-dark-mode.sidebar-icons .navbar-vertical .navbar-brand img.white-logo2 {
    display: none
}

.muze-dark-mode.sidebar-compact .navbar-vertical .navbar-brand img.muze-icon-white,
.muze-dark-mode.sidebar-icons .navbar-vertical .navbar-brand img.muze-icon-white {
    display: block;
    margin: 0 auto
}

.muze-dark-mode.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box,
.muze-dark-mode.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    background-color: #121111
}

.muze-dark-mode .navbar-vertical .navbar-brand img {
    display: none
}

.muze-dark-mode .navbar-vertical .navbar-brand img.white-logo2 {
    display: block
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .docs-version {
    color: #adb5bd;
    border-color: #adb5bd
}

.muze-dark-mode.double-header .header .navbar-brand img,
.muze-dark-mode.top-header .header .navbar-brand img {
    display: none
}

.muze-dark-mode.double-header .header .navbar-brand img.white-logo,
.muze-dark-mode.top-header .header .navbar-brand img.white-logo {
    display: block
}

.muze-dark-mode.double-header .header .customize-btn.btn-dark,
.muze-dark-mode.top-header .header .customize-btn.btn-dark {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
    color: #1e1e1e
}

.muze-dark-mode.double-header .header .customize-btn.btn-dark svg path,
.muze-dark-mode.top-header .header .customize-btn.btn-dark svg path {
    fill: #1e1e1e
}

.muze-dark-mode.double-header .header .customize-btn.btn-dark:hover,
.muze-dark-mode.top-header .header .customize-btn.btn-dark:hover {
    background-color: #ffcd39 !important;
    border-color: #ffcd39 !important
}

.muze-dark-mode.double-header .navbar,
.muze-dark-mode.top-header .navbar {
    border-color: #343a40
}

.muze-dark-mode.double-header .navbar .nav-item .nav-link,
.muze-dark-mode.top-header .navbar .nav-item .nav-link {
    color: #e9ecef
}

.muze-dark-mode.double-header .navbar .nav-item .nav-link svg path,
.muze-dark-mode.top-header .navbar .nav-item .nav-link svg path {
    fill: #adb5bd
}

.muze-dark-mode.double-header .navbar .nav-item .collapse-box,
.muze-dark-mode.top-header .navbar .nav-item .collapse-box {
    background-color: #121111
}

.muze-dark-mode .muze-hamburger img {
    -webkit-filter: brightness(10);
    filter: brightness(10)
}

.muze-dark-mode .avatar .avatar-initials {
    color: #1e1e1e
}

.muze-dark-mode .btn-dark-100:not(:hover) {
    background-color: #343a40;
    color: #adb5bd
}

.muze-dark-mode .btn-dark-100:not(:hover) svg path {
    fill: #adb5bd
}

.muze-dark-mode .btn-dark {
    background-color: #343a40 !important;
    border-color: #343a40 !important
}

.muze-dark-mode .btn-dark:hover {
    background-color: #121111 !important;
    border-color: #121111 !important
}

.muze-dark-mode .link-dark:not(:hover) {
    color: #adb5bd
}

.muze-dark-mode .link-dark:not(:hover) svg path {
    fill: #adb5bd
}

.muze-dark-mode .list-group .text-gray-700 {
    color: #e9ecef !important
}

.muze-dark-mode .list-group .list-group-item {
    background-color: #1a1919;
    border-color: #343a40
}

.muze-dark-mode .list-group .list-group-item .h6 a:not(:hover),
.muze-dark-mode .list-group .list-group-item h6 a:not(:hover) {
    color: #dee2e6
}

.muze-dark-mode .navbar-light {
    background-color: #1e1e1e
}

.muze-dark-mode .header-search form .input-group.bg-white.border-gray-300 {
    background-color: #1a1919 !important
}

.muze-dark-mode .header-search .form-control {
    background-color: #1a1919 !important;
    color: #ced4da
}

.muze-dark-mode .header-search .border-gray-300 {
    border-color: #1a1919 !important
}

.muze-dark-mode .btn-outline-dark:not(:hover) {
    border-color: #adb5bd !important;
    color: #adb5bd !important
}

.muze-dark-mode .btn-outline-dark:not(:hover) svg path {
    fill: #adb5bd
}

.muze-dark-mode .btn-light {
    background-color: #343a40 !important;
    border-color: #343a40 !important;
    color: #adb5bd
}

.muze-dark-mode .btn-light:hover {
    background-color: #212529 !important;
    border-color: #212529 !important
}

.muze-dark-mode .btn-light svg path {
    fill: #fff
}

.muze-dark-mode .header-fixed {
    background-color: #1e1e1e
}

.muze-dark-mode .bg-blue-50,
.muze-dark-mode .bg-white {
    background-color: #1a1919 !important
}

.muze-dark-mode .text-gray-600:not(a) {
    color: #adb5bd !important
}

.muze-dark-mode .back-arrow.border-gray-200 {
    border-color: #1a1919 !important
}

.muze-dark-mode .bg-gray-50 {
    background-color: #343a40 !important
}

.muze-dark-mode .icon-blue-hover .grid-option>a svg path {
    fill: #fff
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg {
    border-color: #343a40
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    color: #adb5bd
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.nav-subtitle .small,
.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.nav-subtitle small {
    color: #6c757d
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link svg path {
    fill: #adb5bd
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active,
.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: #e9ecef
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active:before,
.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover:before {
    background-color: #e9ecef !important
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active svg path,
.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover svg path {
    fill: #e9ecef
}

.muze-dark-mode .navbar-vertical .navbar-vertical-footer {
    background-color: #1e1e1e
}

.muze-dark-mode .navbar-vertical .navbar-vertical-footer.border-gray-50 {
    border-color: #343a40 !important
}

.muze-dark-mode .navbar-vertical .navbar-vertical-footer-list>li a svg path {
    fill: #adb5bd
}

.muze-dark-mode .navbar-vertical .navbar-vertical-footer-list>li a:hover svg path {
    fill: #e9ecef
}

.muze-dark-mode .border-gray-200 {
    border-color: #343a40 !important
}

.muze-dark-mode .display-1,
.muze-dark-mode .display-2,
.muze-dark-mode .display-3,
.muze-dark-mode .display-4,
.muze-dark-mode .display-5,
.muze-dark-mode .display-6,
.muze-dark-mode .h1,
.muze-dark-mode .h2,
.muze-dark-mode .h3,
.muze-dark-mode .h4,
.muze-dark-mode .h5,
.muze-dark-mode .h6,
.muze-dark-mode h1,
.muze-dark-mode h2,
.muze-dark-mode h3,
.muze-dark-mode h4,
.muze-dark-mode h5,
.muze-dark-mode h6 {
    color: #e9ecef
}

.muze-dark-mode .card {
    background-color: #1a1919
}

.muze-dark-mode .card.border-gray-50 {
    border-color: #343a40 !important
}

.muze-dark-mode .card-table th {
    background-color: #343a40;
    border-color: #343a40 !important;
    color: #adb5bd
}

.muze-dark-mode .card-table td {
    border-color: #343a40 !important;
    color: #adb5bd
}

.muze-dark-mode .card-table td .bg-white {
    background-color: #fff !important
}

.muze-dark-mode .card-table td .text-gray-700 {
    color: #e9ecef !important
}

.muze-dark-mode .arrow-icon {
    -webkit-filter: brightness(7);
    filter: brightness(7)
}

.muze-dark-mode footer .navbar li a:not(:hover) {
    color: #adb5bd
}

.muze-dark-mode .dropdown .btn-dark-100:hover {
    background-color: $primary;
    color: #fff
}

.muze-dark-mode .dropdown .btn-dark-100:hover svg path {
    fill: #fff
}

.muze-dark-mode .dropdown-menu {
    background-color: #1e1e1e;
    border: .125rem solid #1a1919;
    box-shadow: none
}

.muze-dark-mode .dropdown-menu .text-black-600 {
    color: #adb5bd
}

.muze-dark-mode .dropdown-menu .dropdown-sub-title {
    color: #6c757d
}

.muze-dark-mode .dropdown-menu .dropdown-divider {
    border-color: #343a40
}

.muze-dark-mode .dropdown-menu .dropdown-item:not(.active):not(:active) {
    color: #e9ecef
}

.muze-dark-mode .dropdown-menu .dropdown-item:focus,
.muze-dark-mode .dropdown-menu .dropdown-item:hover {
    background-color: #1a1919;
    color: #e9ecef !important
}

.muze-dark-mode .dropdown-menu .dropdown-item svg path {
    fill: #adb5bd
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item {
    border-color: #343a40;
    color: #adb5bd !important
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item:hover {
    background-color: #1a1919
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item.unread {
    background-color: #1e1e1e
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item.unread .dropdown-title {
    color: #ced4da
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item .text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode .dropdown-menu .dropdown-footer {
    background-color: #1a1919
}

.muze-dark-mode .dropdown-menu .border-top {
    border-color: #343a40 !important
}

.muze-dark-mode .nav-tabs.nav-tabs-line {
    border-color: #343a40
}

.muze-dark-mode .nav-tabs .nav-link {
    color: #adb5bd
}

.muze-dark-mode .nav-tabs .nav-link.active {
    color: #e9ecef
}

.muze-dark-mode .nav-tabs .nav-link:hover:not(.active) {
    border-color: #e9ecef;
    color: #e9ecef
}

.muze-dark-mode .check-list .form-check-input:not(:checked) {
    border-color: $secondary;
    background-color: transparent
}

.muze-dark-mode .check-list .form-check-input:checked~.form-check-label {
    color: #6c757d
}

.muze-dark-mode .check-list .form-check-label {
    color: #e9ecef
}

.muze-dark-mode .checklist-box textarea.form-control {
    color: #e9ecef;
    background-color: transparent
}

.muze-dark-mode .checklist-box textarea.form-control::-webkit-input-placeholder {
    color: #adb5bd
}

.muze-dark-mode .checklist-box textarea.form-control::-moz-placeholder {
    color: #adb5bd
}

.muze-dark-mode .checklist-box textarea.form-control:-ms-input-placeholder {
    color: #adb5bd
}

.muze-dark-mode .checklist-box textarea.form-control:-moz-placeholder {
    color: #adb5bd
}

.muze-dark-mode.project-management-template .card.border-gray-50 {
    border-color: transparent !important
}

.muze-dark-mode.project-management-template .border-gray-300 {
    border-color: transparent !important
}

.muze-dark-mode.project-management-template .text-black-600 {
    color: #adb5bd
}

.muze-dark-mode.project-management-template .h1.text-black-600 {
    color: #e9ecef
}

.muze-dark-mode.project-management-template .h1~.text-gray-700 {
    color: #adb5bd !important
}

.muze-dark-mode.project-management-template .bg-gray-100 {
    background-color: #1a1919 !important
}

.muze-dark-mode.signup-simple-template.bg-white,
.muze-dark-mode.signup-template.bg-white {
    background-color: #1e1e1e !important
}

.muze-dark-mode.signup-simple-template a.bg-white,
.muze-dark-mode.signup-template a.bg-white {
    background-color: #fff !important
}

.muze-dark-mode.signup-simple-template a.text-gray-600,
.muze-dark-mode.signup-template a.text-gray-600 {
    color: #ced4da !important
}

.muze-dark-mode.signup-simple-template a:hover,
.muze-dark-mode.signup-template a:hover {
    color: $primary !important
}

.muze-dark-mode.signup-simple-template .bg-gray-100,
.muze-dark-mode.signup-template .bg-gray-100 {
    background-color: #1e1e1e !important
}

.muze-dark-mode.signup-simple-template .dark-mode-image,
.muze-dark-mode.signup-template .dark-mode-image {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.muze-dark-mode.signup-simple-template .text-gray-600,
.muze-dark-mode.signup-template .text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.signup-simple-template .text-gray-600 a.text-gray-600:not(:hover),
.muze-dark-mode.signup-template .text-gray-600 a.text-gray-600:not(:hover) {
    color: inherit !important
}

.muze-dark-mode.signup-simple-template hr.bg-gray-200,
.muze-dark-mode.signup-template hr.bg-gray-200 {
    background-color: #343a40 !important
}

.muze-dark-mode.signup-simple-template .form-control,
.muze-dark-mode.signup-template .form-control {
    background-color: #343a40;
    border-color: #343a40;
    color: #ced4da
}

.muze-dark-mode.signup-simple-template .form-check-input:not(:checked),
.muze-dark-mode.signup-template .form-check-input:not(:checked) {
    border-color: #ced4da;
    background-color: #1a1919
}

.muze-dark-mode.signup-simple-template .form-check-label,
.muze-dark-mode.signup-template .form-check-label {
    color: #ced4da !important
}

.muze-dark-mode.signup-simple-template .form-check-label .text-gray-600:not(:hover),
.muze-dark-mode.signup-template .form-check-label .text-gray-600:not(:hover) {
    color: inherit !important
}

.muze-dark-mode.signup-simple-template .text-gray-700:not(a),
.muze-dark-mode.signup-template .text-gray-700:not(a) {
    color: #ced4da !important
}

.muze-dark-mode.signup-simple-template .text-gray-700:not(a) a.text-gray-700:not(:hover),
.muze-dark-mode.signup-template .text-gray-700:not(a) a.text-gray-700:not(:hover) {
    color: inherit !important
}

.muze-dark-mode.signup-simple-template .dark-logo,
.muze-dark-mode.signup-template .dark-logo {
    display: none
}

.muze-dark-mode.signup-simple-template .white-logo,
.muze-dark-mode.signup-template .white-logo {
    display: inline-block
}

.muze-dark-mode.festive-template .border-gray-50 {
    border-color: #1a1919 !important
}

.muze-dark-mode.festive-template .card.bg-gray-800 {
    background-color: #343a40 !important
}

.muze-dark-mode.festive-template .card.bg-gray-800 .btn-dark-100:not(:hover) {
    background-color: #f8f9fa;
    color: #1e1e1e
}

.muze-dark-mode.festive-template .card.bg-gray-800 .btn-dark-100:not(:hover) svg path {
    fill: #1e1e1e
}

.muze-dark-mode.festive-template .card.bg-indigo-light {
    background-color: #975cf6 !important
}

.muze-dark-mode.festive-template .card.bg-indigo-light .btn-dark-100:not(:hover) {
    background-color: #f8f9fa;
    color: #1e1e1e
}

.muze-dark-mode.festive-template .card.bg-indigo-light .btn-dark-100:not(:hover) svg path {
    fill: #1e1e1e
}

.muze-dark-mode.festive-template .card.bg-teal-500 {
    background-color: #20c997 !important
}

.muze-dark-mode.festive-template .card.bg-teal-500 .btn-dark-100:not(:hover) {
    background-color: #f8f9fa;
    color: #1e1e1e
}

.muze-dark-mode.festive-template .card.bg-teal-500 .btn-dark-100:not(:hover) svg path {
    fill: #1e1e1e
}

.muze-dark-mode.festive-template .main-content .btn-dark {
    background-color: #1e1e1e !important;
    border-color: #1e1e1e !important
}

.muze-dark-mode.festive-template .main-content .btn-dark:hover {
    background-color: #343a40 !important;
    border-color: #343a40 !important
}

.muze-dark-mode.festive-template .main-content .bg-gray-100 {
    background-color: #1a1919 !important
}

.muze-dark-mode.festive-template .main-content .border-gray-300 {
    border-color: #1a1919 !important
}

.muze-dark-mode.festive-template .main-content .get-started-two img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.muze-dark-mode.settings-template .sidebar-nav li {
    border-color: #343a40
}

.muze-dark-mode.settings-template .sidebar-nav li a {
    color: #e9ecef
}

.muze-dark-mode.settings-template .sidebar-nav li a svg path {
    fill: #e9ecef
}

.muze-dark-mode.settings-template .sidebar-nav li a.active,
.muze-dark-mode.settings-template .sidebar-nav li a:hover {
    background-color: #1a1919
}

.muze-dark-mode.settings-template aside.border-gray-200 {
    border-color: #1a1919 !important
}

.muze-dark-mode.settings-template .border-gray-50 {
    border-color: #1a1919 !important
}

.muze-dark-mode.settings-template .form-control {
    background-color: #1e1e1e;
    border-color: #1e1e1e
}

.muze-dark-mode.settings-template .text-black-600,
.muze-dark-mode.settings-template .text-gray-700 {
    color: #ced4da !important
}

.muze-dark-mode.settings-template .form-style-two ul li {
    color: #ced4da !important
}

.muze-dark-mode.settings-template .main-content .media .avatar.bg-white {
    background-color: #fff !important
}

.muze-dark-mode.settings-template .main-content .media .media-body span.fs-16 {
    color: #ced4da
}

.muze-dark-mode.settings-template .form-switch .form-check-input:not(:checked) {
    background-color: #343a40;
    border-color: #343a40
}

.muze-dark-mode.settings-template .h6 img,
.muze-dark-mode.settings-template h6 img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.muze-dark-mode.settings-template .card-body .text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.billing-templete .border-gray-50 {
    border-color: #1a1919 !important
}

.muze-dark-mode.billing-templete .text-gray-600.small,
.muze-dark-mode.billing-templete p.text-gray-600,
.muze-dark-mode.billing-templete small.text-gray-600,
.muze-dark-mode.billing-templete span.text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.billing-templete .fs-16.small,
.muze-dark-mode.billing-templete p.fs-16,
.muze-dark-mode.billing-templete small.fs-16,
.muze-dark-mode.billing-templete span.fs-16 {
    color: #e9ecef
}

.muze-dark-mode.billing-templete .badge.bg-blue-50 {
    background-color: #e6f0ff !important
}

.muze-dark-mode.billing-templete .invoice-table .table td {
    border-color: #343a40
}

.muze-dark-mode.billing-templete .invoice-table .table td span.avatar-border {
    border-width: .125rem
}

.muze-dark-mode.billing-templete footer p.text-gray-600 {
    color: #adb5bd !important
}

.muze-dark-mode.invoice-template .border-gray-50 {
    border-color: #1a1919 !important
}

.muze-dark-mode.invoice-template .main-content .dark-logo {
    display: none
}

.muze-dark-mode.invoice-template .main-content .white-logo {
    display: inline-block
}

.muze-dark-mode.invoice-template .main-content p.text-gray-600,
.muze-dark-mode.invoice-template .main-content p.text-gray-700 {
    color: #6c757d !important
}

.muze-dark-mode.invoice-template .main-content .avatar-border {
    border-width: .125rem
}

.muze-dark-mode.invoice-template .main-content .card-table td,
.muze-dark-mode.invoice-template .main-content .card-table th {
    color: #e9ecef
}

.muze-dark-mode.invoice-template .main-content span.text-black-600 {
    color: #e9ecef
}

.muze-dark-mode.invoice-template .main-content footer p.text-gray-600 {
    color: #adb5bd !important
}

.muze-dark-mode.apikeys-template .border-gray-50 {
    border-color: #1a1919 !important
}

.muze-dark-mode.apikeys-template .card.border-gray-200 {
    border: none !important;
    background-color: #1e1e1e
}

.muze-dark-mode.apikeys-template .card-table td {
    color: #e9ecef
}

.muze-dark-mode.apikeys-template .card-table td img {
    -webkit-filter: brightness(0) invert(.8);
    filter: brightness(0) invert(.8)
}

.muze-dark-mode.user-profile-template .h6 a.text-black-600,
.muze-dark-mode.user-profile-template h6 a.text-black-600 {
    color: #e9ecef
}

.muze-dark-mode.user-profile-template .border-gray-50 {
    border: none !important
}

.muze-dark-mode.user-profile-template p .text-gray-700,
.muze-dark-mode.user-profile-template p.text-gray-700 {
    color: #ced4da !important
}

.muze-dark-mode.user-profile-template p svg path {
    fill: #ced4da
}

.muze-dark-mode.user-profile-template .customize-sidebar p .text-gray-700,
.muze-dark-mode.user-profile-template .customize-sidebar p.text-gray-700 {
    color: #6c757d !important
}

.muze-dark-mode.user-profile-template .customize-sidebar p svg path {
    fill: #6c757d
}

.muze-dark-mode.user-profile-template span.text-gray-700 {
    color: #e9ecef !important
}

.muze-dark-mode.user-profile-template span.text-gray-800 {
    color: #ced4da
}

.muze-dark-mode.user-profile-template .progress {
    background-color: #343a40
}

.muze-dark-mode.user-profile-template .profile-list li span+span {
    color: #ced4da
}

.muze-dark-mode.user-profile-template .card-body .avatar.bg-white {
    background-color: #fff !important
}

.muze-dark-mode.user-profile-template .media-body .text-black-600 {
    color: #e9ecef
}

.muze-dark-mode.user-profile-template .media-body .text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.user-profile-template .list-group .list-group-item .h6 a:not(:hover),
.muze-dark-mode.user-profile-template .list-group .list-group-item h6 a:not(:hover) {
    color: #e9ecef
}

.muze-dark-mode.user-profile-template strong.text-black-600 {
    color: #e9ecef !important
}

.muze-dark-mode.user-profile-template .card-text.text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.user-profile-template .small.text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.user-profile-template .small.text-gray-600 svg path {
    fill: #6c757d
}

.muze-dark-mode.user-profile-template form .input-group.bg-white {
    background-color: #343a40 !important
}

.muze-dark-mode.user-profile-template form .input-group.bg-white.border-gray-300 {
    border-color: #343a40 !important
}

.muze-dark-mode.user-profile-template form .form-control {
    background-color: #343a40;
    color: #fff
}

.muze-dark-mode.user-profile-template .h5 a.text-black-600,
.muze-dark-mode.user-profile-template h5 a.text-black-600 {
    color: #e9ecef
}

.muze-dark-mode.user-profile-template .avatar-initials {
    color: #1e1e1e
}

.muze-dark-mode.pricing-template .bg-blue-50 {
    background-color: #e6f0ff !important
}

.muze-dark-mode.pricing-template .plan-list li {
    color: #e9ecef
}

.muze-dark-mode.pricing-template .small.text-gray-600 a.text-black-600:not(:hover) {
    color: #e9ecef
}

.muze-dark-mode.pricing-template .bg-blue-50.alert-dismissible {
    background-color: #1a1919 !important
}

.muze-dark-mode .muze-collapes .card {
    border-color: #343a40 !important;
    background-color: transparent
}

.muze-dark-mode .muze-collapes .card .card-header .btn-light {
    color: #e9ecef
}

.muze-dark-mode .muze-collapes .card .card-header .btn-light:after,
.muze-dark-mode .muze-collapes .card .card-header .btn-light:before {
    background-color: #e9ecef
}

.muze-dark-mode .muze-collapes .card .card-body p.text-gray-700 {
    color: #ced4da !important
}

.muze-dark-mode .search-less .search-left img {
    -webkit-filter: brightness(0) invert(.7);
    filter: brightness(0) invert(.7)
}

.muze-dark-mode .search-less .search-left .form-control {
    background-color: #1a1919 !important;
    color: #fff
}

.muze-dark-mode .customize-sidebar {
    color: #1a1919
}

.muze-dark-mode .customize-sidebar .display-1,
.muze-dark-mode .customize-sidebar .display-2,
.muze-dark-mode .customize-sidebar .display-3,
.muze-dark-mode .customize-sidebar .display-4,
.muze-dark-mode .customize-sidebar .display-5,
.muze-dark-mode .customize-sidebar .display-6,
.muze-dark-mode .customize-sidebar .h1,
.muze-dark-mode .customize-sidebar .h2,
.muze-dark-mode .customize-sidebar .h3,
.muze-dark-mode .customize-sidebar .h4,
.muze-dark-mode .customize-sidebar .h5,
.muze-dark-mode .customize-sidebar .h6,
.muze-dark-mode .customize-sidebar h1,
.muze-dark-mode .customize-sidebar h2,
.muze-dark-mode .customize-sidebar h3,
.muze-dark-mode .customize-sidebar h4,
.muze-dark-mode .customize-sidebar h5,
.muze-dark-mode .customize-sidebar h6 {
    color: #1e1e1e
}

.muze-dark-mode .customize-sidebar .btn-light {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #1e1e1e
}

.muze-dark-mode .customize-sidebar .btn-light:hover {
    background-color: #e6f0ff !important;
    border-color: #e6f0ff !important
}

.muze-dark-mode .customize-sidebar .btn-light:hover svg path {
    fill: $primary
}

.muze-dark-mode .customize-sidebar .btn-light svg path {
    fill: #1e1e1e
}

.muze-dark-mode .customize-sidebar .border-gray-200 {
    border-color: #e9ecef !important
}

.muze-dark-mode .customize-sidebar .bg-white {
    background-color: #fff !important
}

.muze-dark-mode .customize-sidebar .text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode .customize-sidebar .btn-outline-dark:not(:hover) {
    border-color: #212529 !important;
    color: #212529 !important
}

.muze-dark-mode.help-center-template .main-content .h5 a.text-black-600:not(:hover),
.muze-dark-mode.help-center-template .main-content h5 a.text-black-600:not(:hover) {
    color: #e9ecef
}

.muze-dark-mode.help-center-template .main-content p.text-black-600,
.muze-dark-mode.help-center-template .main-content p.text-gray-700 {
    color: #ced4da !important
}

.muze-dark-mode.help-center-template .main-content form .form-control,
.muze-dark-mode.help-center-template .main-content form .form-select {
    background-color: #1e1e1e;
    border-color: #1e1e1e;
    color: #fff
}

.muze-dark-mode.help-center-template .main-content form .form-check .form-check-input:not(:checked) {
    background-color: transparent;
    border-color: #adb5bd
}

.muze-dark-mode.help-center-template .main-content form .form-check .form-check-label {
    color: #ced4da
}

.muze-dark-mode.empty-state-template p.text-black-600 {
    color: #6c757d
}

.muze-dark-mode .chat-header a.circle:not(:hover) {
    background-color: #343a40
}

.muze-dark-mode .chat-header a.circle:not(:hover) svg path {
    fill: #e9ecef
}

.muze-dark-mode.chat-template .nav.nav-pills .nav-link {
    color: #e9ecef
}

.muze-dark-mode.chat-template .message-box {
    background-color: #343a40
}

.muze-dark-mode.chat-template .message-box:before {
    border-right: 1.25rem solid #343a40
}

.muze-dark-mode.chat-template .message-box p {
    color: #e9ecef
}

.muze-dark-mode.chat-template .chat-list a {
    background-color: #1e1e1e
}

.muze-dark-mode.chat-template .chat-list a.active {
    background-color: #1a1919
}

.muze-dark-mode.chat-template .chat-list a.unread {
    background-color: #343a40
}

.muze-dark-mode.chat-template .chat-list a.unread .h6,
.muze-dark-mode.chat-template .chat-list a.unread h6 {
    color: #e9ecef
}

.muze-dark-mode.chat-template .chat-list a p {
    color: #adb5bd
}

.muze-dark-mode.chat-template .chat-list a .media-body span.text-muted {
    color: #6c757d !important
}

.muze-dark-mode.chat-template .attachment {
    background-color: #343a40;
    color: #e9ecef
}

.muze-dark-mode.chat-template .attachment svg path {
    fill: #e9ecef
}

.muze-dark-mode.chat-template .attachment:hover {
    color: $primary
}

.muze-dark-mode.chat-template .attachment:hover svg path {
    fill: $primary
}

.muze-dark-mode.chat-template .chat-items hr.bg-gray-200 {
    background-color: #343a40
}

.muze-dark-mode.chat-template .chat-items span.text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.chat-template .chat-message .text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.chat-template .message-sender .message-box {
    background-color: $primary
}

.muze-dark-mode.chat-template .message-sender .message-box:before {
    border-right-color: $primary
}

.muze-dark-mode.chat-template .message-footer .form-control {
    background-color: transparent;
    color: #e9ecef
}

.muze-dark-mode.chat-template .message-footer a:not(:hover) svg path {
    fill: #e9ecef
}

.muze-dark-mode.file-manager-template figure.border-gray-200 {
    border: none !important
}

.muze-dark-mode.file-manager-template figure .arrow-icon {
    -webkit-filter: brightness(4);
    filter: brightness(4)
}

.muze-dark-mode.file-manager-template .text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.file-manager-template .badge-soft-primary {
    background-color: #343a40;
    color: #fff
}

.muze-dark-mode.calendar-template .fc-theme-standard .fc-scrollgrid,
.muze-dark-mode.calendar-template .fc-theme-standard td,
.muze-dark-mode.calendar-template .fc-theme-standard th {
    border-color: #343a40
}

.muze-dark-mode.calendar-template .calendar-box a {
    color: #ced4da
}

.muze-dark-mode.all-projects-template .nav-pills .nav-link {
    color: #e9ecef
}

.muze-dark-mode.all-projects-template form .input-group.border-gray-300 {
    border-color: #343a40 !important
}

.muze-dark-mode.all-projects-template form .input-group.bg-white {
    background-color: #343a40 !important
}

.muze-dark-mode.all-projects-template form .input-group img {
    -webkit-filter: brightness(2);
    filter: brightness(2)
}

.muze-dark-mode.all-projects-template form .input-group .form-control {
    background-color: inherit
}

.muze-dark-mode.all-projects-template form .input-group .form-control::-webkit-input-placeholder {
    color: #ced4da
}

.muze-dark-mode.all-projects-template form .input-group .form-control::-moz-placeholder {
    color: #ced4da
}

.muze-dark-mode.all-projects-template form .input-group .form-control:-ms-input-placeholder {
    color: #ced4da
}

.muze-dark-mode.all-projects-template form .input-group .form-control:-moz-placeholder {
    color: #ced4da
}

.muze-dark-mode.all-projects-template .card-body a.text-black-600:not(:hover) {
    color: #e9ecef
}

.muze-dark-mode.all-projects-template .group-cards .card.border-gray-200 {
    border: none !important
}

.muze-dark-mode.all-projects-template .group-cards .card span.text-gray-700 {
    color: #e9ecef !important
}

.muze-dark-mode.all-projects-template span.text-gray-600.small {
    color: #e9ecef !important
}

.muze-dark-mode.all-projects-template .badge.bg-gray-200 {
    background-color: #343a40 !important
}

.muze-dark-mode.all-projects-template .badge.bg-gray-200.text-gray-700 {
    color: #adb5bd !important
}

.muze-dark-mode .step-list li:not(:first-child):not(.active):before {
    background-color: #343a40
}

.muze-dark-mode .step-list li:not(.active) .circle {
    background-color: $secondary
}

.muze-dark-mode.new-projects-template .card.border-gray-200 {
    border: none !important
}

.muze-dark-mode.new-projects-template .form-label {
    color: #e9ecef
}

.muze-dark-mode.new-projects-template .form-control {
    background-color: #1e1e1e;
    border-color: #1e1e1e;
    color: #e9ecef
}

.muze-dark-mode .ql-toolbar.ql-snow {
    border-color: #1e1e1e;
    background-color: #1e1e1e
}

.muze-dark-mode .ql-container.ql-snow {
    border-color: #1e1e1e;
    background-color: #1e1e1e;
    color: #e9ecef
}

.muze-dark-mode .ql-editor .ql-blank {
    color: #adb5bd
}

.muze-dark-mode .ql-formats button:not(:hover) svg path {
    stroke: #e9ecef
}

.muze-dark-mode .ql-formats button:not(:hover) .ql-stroke {
    stroke: #e9ecef
}

.muze-dark-mode .ql-formats button:not(:hover) .ql-fill {
    fill: #e9ecef
}

.muze-dark-mode.project-details-template .main-content .card.border-gray-200 {
    border: none !important
}

.muze-dark-mode.project-details-template .main-content .card .card-body a.text-black-600 {
    color: #e9ecef
}

.muze-dark-mode.project-details-template .main-content .card .card-body span.text-gray-700 {
    color: #e9ecef !important
}

.muze-dark-mode.project-details-template .main-content .card .card-body p.text-gray-600:not(.card-text) {
    color: #6c757d !important
}

.muze-dark-mode.project-details-template .main-content .card.border-gray-50 {
    border: none !important
}

.muze-dark-mode.project-details-template .main-content .list-group .avatar-border-xs {
    border-color: transparent
}

.muze-dark-mode.project-details-template .main-content .list-group p.text-gray-600 {
    color: #6c757d !important
}

.muze-dark-mode.project-details-template .main-content .list-group span.text-gray-800 {
    color: #ced4da !important
}

.muze-dark-mode.project-details-template .main-content .list-group strong.text-black-600 {
    color: #e9ecef !important
}

.muze-dark-mode.project-details-template .main-content .list-group .link-primary:hover {
    color: #ced4da
}

.muze-dark-mode.project-details-template .main-content .list-group .link-primary:hover svg path {
    fill: #ced4da
}

.muze-dark-mode.project-details-template .main-content .list-group .list-group-item figure.border-gray-200 {
    border: none !important;
    background-color: #1e1e1e
}

.muze-dark-mode .activity-list .list-group-item:not(:last-child):before {
    background-color: #343a40
}

.muze-rtl ul {
    padding-right: 0
}

.muze-rtl .main-content {
    margin-left: 0;
    margin-right: 16.0625rem
}

.muze-rtl .header-fixed {
    left: 0;
    right: 16.0625rem
}

.muze-rtl .avatar-group .avatar+.avatar {
    margin-left: 0;
    margin-right: -1.4375rem
}

.muze-rtl .avatar-group .avatar-lg+.avatar-lg {
    margin-left: 0;
    margin-right: -1.25rem
}

.muze-rtl .avatar-group .avatar-xs+.avatar-xs {
    margin-left: 0;
    margin-right: -.625rem
}

.muze-rtl .end-0 {
    left: 0 !important;
    right: auto !important
}

.muze-rtl .navbar-vertical.navbar-expand-lg {
    border-left: .0625rem solid #e9ecef;
    border-right: none
}

.muze-rtl .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
    margin-left: 0;
    margin-right: auto
}

.muze-rtl .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .docs-version {
    margin-left: 0;
    margin-right: .5rem
}

.muze-rtl .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
    padding-left: 2rem;
    padding-right: 3.8rem
}

.muze-rtl .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link {
    padding-left: 2rem;
    padding-right: 4.8rem
}

.muze-rtl .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link:before {
    margin-right: 0;
    margin-left: .4375rem
}

.muze-rtl .navbar-vertical .navbar-vertical-footer-list {
    padding-right: 0
}

.muze-rtl .dropdown-menu[style] {
    left: 0 !important
}

.muze-rtl .dropdown-menu.dropdown-menu-end {
    left: 0 !important;
    right: auto
}

.muze-rtl .dropdown-menu li {
    text-align: right
}

.muze-rtl .dropdown-menu .dropdown-item .media-body {
    text-align: right
}

.muze-rtl [class*=me-]:not(.align-items-center) {
    margin-right: 0 !important
}

.muze-rtl [class*=ms-]:not(.align-items-center) {
    margin-left: 0 !important
}

.muze-rtl [class*=ms-]:not(.align-items-center).ms-auto {
    margin-right: auto !important
}

.muze-rtl .text-end {
    text-align: left !important
}

.muze-rtl .form-control {
    text-align: right
}

.muze-rtl .form-check {
    padding-left: 0;
    margin-right: 0
}

.muze-rtl .form-check .form-check-input {
    margin-left: 0;
    float: right
}

.muze-rtl .form-switch {
    padding-left: 0
}

.muze-rtl .end-16 {
    left: 1rem;
    right: auto
}

.muze-rtl .get-started-img {
    right: auto;
    left: 3.125rem
}

.muze-rtl .arrow-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.muze-rtl .get-startedtwo {
    left: 1.875rem;
    right: auto
}

.muze-rtl .header-body .back-arrow svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.muze-rtl .header-body .icon-blue-hover.ps-0 {
    padding-left: calc(var(--bs-gutter-x)/ 2) !important
}

.muze-rtl .header-body .notification {
    margin-right: 0 !important
}

.muze-rtl .header-body .notification .status {
    right: -.4375rem
}

.muze-rtl .header-body .header-search .form-control {
    background-color: transparent
}

.muze-rtl.muze-dark-mode .navbar-vertical.navbar-expand-lg {
    border-color: #343a40
}

.muze-rtl.muze-dark-mode .message-box:before {
    border-right: none;
    border-left: 1.25rem solid #343a40
}

.muze-rtl.sidebar-icons .main-content {
    margin-left: 0;
    margin-right: 5rem
}

.muze-rtl.sidebar-icons .header-fixed {
    left: 0;
    right: 5rem
}

.muze-rtl.sidebar-icons .back-arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.muze-rtl.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link .ms-2 {
    margin-right: 0 !important
}

.muze-rtl.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    left: auto;
    right: 5rem
}

.muze-rtl.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box .nav-link {
    padding-left: .9375rem;
    padding-right: 1.5625rem
}

.muze-rtl.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .status {
    left: 0;
    right: .375rem
}

.muze-rtl.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
    padding: .5rem .9375rem
}

.muze-rtl.sidebar-compact .main-content {
    margin-left: 0;
    margin-right: 9.0625rem
}

.muze-rtl.sidebar-compact .back-arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.muze-rtl.sidebar-compact .header-fixed {
    left: 0;
    right: 9.0625rem
}

.muze-rtl.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link .ms-2 {
    margin-right: 0 !important
}

.muze-rtl.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    left: auto;
    right: 9.0625rem
}

.muze-rtl.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box .nav-link {
    padding-left: .9375rem;
    padding-right: 1.5625rem
}

.muze-rtl.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .status {
    left: 0;
    right: -.5rem
}

.muze-rtl.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
    padding: .5rem .9375rem
}

.muze-rtl .customize-sidebar {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    right: auto;
    left: 0
}

.muze-rtl.customize-box .customize-sidebar {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.muze-rtl footer .navbar li {
    padding-left: 0;
    padding-right: 3.125rem
}

.muze-rtl .text-md-end {
    text-align: left !important
}

.muze-rtl .start-50 {
    left: auto !important;
    right: 50% !important
}

.muze-rtl .translate-middle {
    -webkit-transform: translate(50%, -50%) !important;
    transform: translate(50%, -50%) !important
}

.muze-rtl .chat-message {
    padding-left: 0;
    padding-right: 1.5625rem
}

.muze-rtl .message-box:before {
    left: auto;
    right: -1.125rem;
    border-right: none;
    border-left: 1.25rem solid #f8f9fa
}

.muze-rtl .message-sender .message-box:before {
    border-left: 1.25rem solid $primary
}

.muze-rtl .message-sender .message-files {
    padding-left: 0;
    padding-right: 4.6875rem
}

.muze-rtl .message-sender .message-files ul {
    padding-right: 0
}

.muze-rtl .message-footer a:not(.btn) {
    margin-right: 0;
    margin-left: 2.5rem
}

.muze-rtl .chat-list a .unread-status {
    right: auto;
    left: 0
}

.muze-rtl .chat-list a p {
    padding-right: 0;
    padding-left: 3.4375rem
}

.muze-rtl .checklist-box textarea.form-control {
    padding-left: .9375rem;
    padding-right: 0
}

.muze-rtl.file-manager-template .dropdown.end-0 {
    right: auto !important;
    left: .5rem !important
}

.muze-rtl.file-manager-template .arrow-icon {
    -webkit-transform: none;
    transform: none
}

.muze-rtl.file-manager-template .alert-dismissible .arrow-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.muze-rtl .ms-auto {
    margin-left: 0 !important;
    margin-right: auto !important
}

.muze-rtl .muze-collapes .card .card-header .btn-light {
    padding-left: 2rem !important;
    padding-right: 0 !important;
    text-align: right !important
}

.muze-rtl .muze-collapes .card .card-header .btn-light:after {
    right: auto;
    left: 0
}

.muze-rtl .muze-collapes .card .card-header .btn-light:before {
    right: auto;
    left: .5625rem
}

.muze-rtl .form-select {
    background-position: left .5rem center;
    padding-left: 1.75rem;
    padding-right: .75rem
}

.muze-rtl.help-center-template .form-check .form-check-input {
    margin-left: .625rem
}

.muze-rtl.pricing-template .badge.position-absolute.end-0 {
    right: auto !important;
    left: 1rem !important
}

.muze-rtl .ql-editor {
    text-align: right
}

.muze-rtl .step-list li:not(:first-child):before {
    left: auto;
    right: -50%
}

.muze-rtl .activity-list .list-group-item:not(:last-child):before {
    left: auto;
    right: 4.0625rem
}

.muze-rtl .get-startedthree {
    right: auto;
    left: -18.4375rem
}

.muze-rtl .sidebar-nav li a {
    border-left: none;
    border-right: .25rem solid transparent
}

.muze-rtl .sidebar-nav li a.active,
.muze-rtl .sidebar-nav li a:hover {
    border-color: $primary
}

.muze-rtl .ms-md-4 {
    margin-left: 0 !important
}

.muze-rtl .btn-link svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.muze-rtl .profile-list li span+span {
    padding-left: 0;
    padding-right: .75rem
}

.muze-rtl.top-header .navbar {
    border-left: none;
    border-right: .0625rem solid #e9ecef;
    padding-left: 0;
    padding-right: .625rem;
    margin-left: 0;
    margin-right: 1.5rem
}

.muze-rtl.double-header .header .customize-btn .ps-2,
.muze-rtl.top-header .header .customize-btn .ps-2 {
    padding-left: 0 !important
}

.muze-rtl.double-header .main-content,
.muze-rtl.top-header .main-content {
    margin-right: 0
}

.muze-rtl.double-header .header-fixed,
.muze-rtl.top-header .header-fixed {
    right: 0
}

.muze-rtl.double-header .header-body .icon-blue-hover.ps-0,
.muze-rtl.top-header .header-body .icon-blue-hover.ps-0 {
    padding-left: 0 !important
}

.muze-rtl.double-header .header-body .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.muze-rtl.top-header .header-body .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: 0;
    margin-left: 0
}

.muze-rtl.double-header .navbar .nav-item .nav-link[data-bs-toggle=collapse]:after,
.muze-rtl.top-header .navbar .nav-item .nav-link[data-bs-toggle=collapse]:after {
    margin-left: 0;
    margin-right: .8125rem
}

.muze-rtl.double-header .navbar .nav-item .nav-link .docs-version,
.muze-rtl.top-header .navbar .nav-item .nav-link .docs-version {
    margin-left: 0;
    margin-right: .375rem
}

.muze-rtl.double-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link:after,
.muze-rtl.top-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link:after {
    margin-left: 0;
    margin-right: auto
}

.muze-rtl.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box,
.muze-rtl.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box {
    padding-left: 0;
    padding-right: .625rem
}

.muze-rtl.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav,
.muze-rtl.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav {
    padding: 0
}

.muze-rtl.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link:before,
.muze-rtl.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav-link:before {
    margin-right: 0;
    margin-left: .4375rem
}

.muze-rtl.double-header .header-search .input-group {
    border-left: none !important;
    border-right: .0625rem solid $secondary !important;
    padding-left: .25rem !important;
    padding-right: 1.5625rem !important
}

.muze-rtl.double-header .navbar {
    padding-left: 0;
    padding-right: .625rem
}

.muze-rtl.double-header .navbar .nav-item .collapse-box {
    left: auto;
    right: 0
}

.muze-rtl.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box {
    padding-left: 0;
    padding-right: .625rem
}

.muze-rtl.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box .nav {
    padding: 0
}

.muze-rtl.double-header .header-body>.row {
    margin-right: 0
}

.muze-rtl.festive-template .main-content .border-end {
    border-right: none !important
}

.muze-rtl.billing-templete .media .ps-xxl-5 {
    padding-left: calc(var(--bs-gutter-x)/ 2) !important
}

.muze-rtl.billing-templete .media .ps-xxl-5 .d-flex.ps-xxl-5 {
    padding-left: 0 !important
}

.muze-rtl .plan-list li span {
    padding-left: 0;
    padding-right: .8125rem
}

.muze-rtl.empty-state-template .btn-primary svg,
.muze-rtl.empty-state-template .btn-soft-primary svg,
.muze-rtl.pricing-template .btn-primary svg,
.muze-rtl.pricing-template .btn-soft-primary svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.muze-rtl .sidebar-style .form-check {
    padding-right: 0
}

.muze-rtl .sidebar-style .form-check-input {
    right: 1.5em;
    left: auto
}

.muze-rtl .sidebar-style .form-check-label span {
    padding-left: 0;
    padding-right: 1.5em
}

body.container .bg-blue-50.p-md-5 {
    padding: 1.5rem !important
}

body.container:not(.top-header):not(.double-header) .header-fixed {
    left: auto;
    right: auto;
    position: static
}

body.container:not(.top-header):not(.double-header) .main-content {
    margin-top: 0
}

body.container:not(.top-header):not(.double-header) .navbar-vertical.navbar-expand-lg {
    background-color: transparent;
    border-left: .0625rem solid #e9ecef
}

body.container:not(.top-header):not(.double-header) .navbar-vertical .navbar-vertical-footer {
    background-color: transparent
}

body.container:not(.top-header):not(.double-header).muze-rtl .navbar-vertical.navbar-expand-lg {
    border-right: .0625rem solid #e9ecef
}

body.container:not(.top-header):not(.double-header).muze-rtl .muze-chats {
    border-right: none;
    border-left: .0625rem solid #e9ecef
}

body.container:not(.top-header):not(.double-header).muze-rtl .border-end {
    border-right: none !important
}

body.container:not(.top-header):not(.double-header).muze-dark-mode .navbar-vertical.navbar-expand-lg {
    border-left: .0625rem solid #343a40
}

body.container:not(.top-header):not(.double-header).muze-dark-mode.muze-rtl .navbar-vertical.navbar-expand-lg {
    border-right: .0625rem solid #343a40
}

body.container:not(.top-header):not(.double-header).muze-dark-mode.muze-rtl .muze-chats {
    border-right: none;
    border-left: .0625rem solid #343a40
}

body.container:not(.top-header):not(.double-header).muze-dark-mode.muze-rtl .border-end {
    border-right: none !important
}

body.container:not(.muze-rtl) .main-content .p-xxl-5,
body.container:not(.muze-rtl) .main-content .px-xxl-5 {
    padding-right: 0 !important
}

body.container.muze-rtl .main-content .p-xxl-5,
body.container.muze-rtl .main-content .px-xxl-5 {
    padding-left: 0 !important
}

body.container.muze-rtl.double-header .icon-blue-hover .ms-xxl-5,
body.container.muze-rtl.top-header .icon-blue-hover .ms-xxl-5 {
    margin-right: 1.5rem !important
}

body.container.muze-rtl.double-header .navbar,
body.container.muze-rtl.top-header .navbar {
    margin-right: 0;
    padding-right: 0
}

body.container.muze-rtl.double-header .main-content .header .px-xxl-5,
body.container.muze-rtl.top-header .main-content .header .px-xxl-5 {
    padding-right: 0 !important
}

body.container.muze-rtl.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    right: auto;
    margin-right: 9.0625rem
}

body.container.muze-rtl.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box {
    margin-right: 0
}

body.container.muze-rtl.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    right: auto;
    margin-right: 5rem
}

body.container.muze-rtl.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box {
    margin-right: 0
}

body.container.muze-rtl.project-management-template .get-startedthree {
    left: -27rem
}

body.container.double-header .main-content>.p-xxl-5,
body.container.double-header .main-content>.px-xxl-5,
body.container.top-header .main-content>.p-xxl-5,
body.container.top-header .main-content>.px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

body.container.double-header .main-content .header .px-xxl-5,
body.container.top-header .main-content .header .px-xxl-5 {
    padding-left: 0 !important
}

body.container.double-header .main-content .header .customize-btn,
body.container.top-header .main-content .header .customize-btn {
    padding-left: 1.15rem;
    padding-right: 1.15rem
}

body.container.double-header .main-content .header .customize-btn svg,
body.container.top-header .main-content .header .customize-btn svg {
    margin-top: -.125rem
}

body.container.double-header .main-content .header .customize-btn span,
body.container.top-header .main-content .header .customize-btn span {
    display: none
}

body.container.double-header .main-content .header .container-fluid,
body.container.double-header .main-content .header .container-lg,
body.container.double-header .main-content .header .container-md,
body.container.double-header .main-content .header .container-sm,
body.container.double-header .main-content .header .container-xl,
body.container.double-header .main-content .header .container-xxl,
body.container.top-header .main-content .header .container-fluid,
body.container.top-header .main-content .header .container-lg,
body.container.top-header .main-content .header .container-md,
body.container.top-header .main-content .header .container-sm,
body.container.top-header .main-content .header .container-xl,
body.container.top-header .main-content .header .container-xxl {
    max-width: 82.5rem;
    padding-right: var(--bs-gutter-x, .75rem) !important;
    padding-left: var(--bs-gutter-x, .75rem) !important;
    margin-right: auto;
    margin-left: auto
}

body.container.double-header.festive-template .main-content .col-xxl-9>.p-xxl-5.border-end,
body.container.top-header.festive-template .main-content .col-xxl-9>.p-xxl-5.border-end {
    padding-left: 0 !important;
    padding-right: 0 !important
}

body.container.double-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5,
body.container.top-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5 {
    margin-left: 1.5rem !important
}

body.container.double-header:not(.muze-rtl) .navbar,
body.container.top-header:not(.muze-rtl) .navbar {
    margin-left: .625rem
}

body.container.double-header .navbar .nav-item .nav-link,
body.container.top-header .navbar .nav-item .nav-link {
    padding: .6875rem .75rem
}

body.container.double-header .header:before {
    content: '';
    background-color: #1a1919;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 6rem
}

body.container.double-header .header .navbar {
    padding-left: 0;
    margin-left: 0
}

body.container.double-header .double-header-nav {
    padding-left: 0;
    padding-right: 0
}

body.container.double-header .double-header-nav .navbar-expand-lg .navbar-nav {
    margin: 0 -.75rem
}

body.container.sidebar-compact:not(.muze-rtl) .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    left: auto;
    margin-left: 9.0625rem
}

body.container.sidebar-compact:not(.muze-rtl) .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box {
    margin-left: 0
}

body.container.sidebar-icons:not(.muze-rtl) .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
    left: auto;
    margin-left: 5rem
}

body.container.sidebar-icons:not(.muze-rtl) .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box .collapse-box {
    margin-left: 0
}

body.container.muze-dark-mode.top-header .main-content .header .px-xxl-5 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

body.container.muze-dark-mode.top-header .header-body>.row {
    margin-right: 0
}

body.container.muze-dark-mode .muze-chats {
    border-right: .0625rem solid #343a40
}

body.container.project-management-template .main-content .bg-gray-100.p-md-5 {
    padding-left: 1rem !important;
    padding-right: 1.5rem !important
}

body.container.project-management-template .main-content .bg-gray-100.p-md-5 .col-xl-6 {
    width: 60%
}

body.container.project-management-template:not(.muze-rtl) .get-startedthree {
    right: -27rem
}

body.container .muze-chats {
    border-right: .0625rem solid #e9ecef
}

body.container .bg-blue-50.alert-dismissible .ps-xl-3 .h2,
body.container .bg-blue-50.alert-dismissible .ps-xl-3 h2 {
    font-size: 1.5rem;
    line-height: 2rem
}

@media screen and (min-width:1400px) {
    body.container .main-content .col-xxl-9 {
        width: 66.667%
    }

    body.container .main-content .col-xxl-3 {
        width: 33.333%
    }

    body.container .bg-blue-50.alert-dismissible .ps-xl-3 {
        padding-left: 0 !important
    }

    body.container .bg-blue-50.alert-dismissible .col-xxl-4 {
        width: 50%
    }

    body.container.analytics-template .col-xxl-3 {
        width: 50%
    }

    body.container.apikeys-template .main-content .col-xxl-4 {
        width: 50%
    }

    body.container.user-profile-template .col-xxl-2 {
        width: 25%
    }

    body.container.user-profile-template .px-md-4 {
        padding-left: .65rem !important;
        padding-right: .65rem !important
    }

    body.container.user-profile-template .row.ps-xl-3 {
        padding-left: 0 !important
    }

    body.container.user-profile-template .btn.m-md-3 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    body.container.user-profile-template .card-body .p-xl-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    body.container.team-template .card-body .px-md-3 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    body.container.help-center-template .col-xxl-6.p-md-5,
    body.container.pricing-template .col-xxl-6.p-md-5 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    body.container.help-center-template .h-100.px-lg-5,
    body.container.pricing-template .h-100.px-lg-5 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    body.container.help-center-template .after-header~.px-xxl-5,
    body.container.pricing-template .after-header~.px-xxl-5 {
        padding-right: 3rem !important
    }

    body.container.help-center-template.muze-rtl .after-header~.px-xxl-5,
    body.container.pricing-template.muze-rtl .after-header~.px-xxl-5 {
        padding-left: 3rem !important
    }

    body.container.file-manager-template .has-xxl-5 {
        width: 33.333%
    }

    body.container.chat-template .muze-chats .px-xxl-5 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    body.container.festive-template .main-content .col-xxl-9 {
        width: 100%
    }

    body.container.festive-template .main-content .col-xxl-9>.p-xxl-5 {
        padding-bottom: 0 !important
    }

    body.container.festive-template .main-content .col-xxl-9>.p-xxl-5.border-end {
        border: none !important
    }

    body.container.festive-template .main-content .col-xxl-3 {
        width: 100%
    }

    body.container.festive-template footer {
        margin-top: 3rem
    }
}

@media screen and (min-width:1200px) and (max-width:1650px) {
    .festive-template .p-xxl-5 {
        padding: 1.5rem !important
    }

    .festive-template .px-xxl-5 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .muze-rtl.top-header .icon-blue-hover .ms-xxl-5 {
        margin-right: 1.2rem !important
    }
}

@media screen and (min-width:1400px) and (max-width:1599px) {
    .festive-template .col-xxl-9 {
        width: 70%
    }

    .festive-template .col-xxl-3 {
        width: 30%
    }

    .h3,
    h3 {
        font-size: 1.5rem
    }

    .title-box {
        min-height: 4.6875rem
    }

    .nav.nav-pills .nav-link {
        padding: .5rem 1.2rem
    }

    .chat-items {
        height: calc(100vh - 22.25rem)
    }

    .muze-rtl.top-header .icon-blue-hover .ms-xxl-5 {
        margin-right: 1.2rem !important
    }

    .muze-rtl.top-header .navbar {
        padding-right: .625rem;
        padding-left: 0;
        margin-left: 0;
        margin-right: .675rem
    }

    .muze-rtl.top-header .navbar .nav-item .nav-link {
        padding: .6875rem
    }

    .double-header:not(.muze-rtl) .navbar,
    .top-header:not(.muze-rtl) .navbar {
        margin-left: 1rem
    }

    .double-header:not(.muze-rtl) .navbar .nav-item .nav-link,
    .top-header:not(.muze-rtl) .navbar .nav-item .nav-link {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .double-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5,
    .top-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5 {
        margin-left: 1.6rem !important
    }
}

@media screen and (min-width:1520px) {
    .has-xxl-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .has-xxl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 29.0625rem
    }

    .has-xxl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: calc(100% - 29.0625rem)
    }
}

@media screen and (min-width:1200px) and (max-width:1519px) {
    .get-started-two {
        max-width: 15rem
    }

    .has-xxl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25.3125rem
    }

    .has-xxl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: calc(100% - 25.3125rem)
    }

    body.container.project-details-template .card-body .btn-xl {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

@media screen and (max-width:1399px) {
    .chat-list a {
        padding: 1.125rem 1rem 1.125rem .6875rem
    }

    .chat-items {
        height: calc(100vh - 21.25rem);
        padding: .9375rem 0
    }

    .message-sender {
        padding-left: 1.25rem
    }

    .chat-template .nav.nav-pills .nav-link {
        padding: .5rem 1.2rem
    }

    .top-header .navbar {
        padding-left: 0;
        margin-left: 0
    }

    .top-header .navbar .nav-item .nav-link {
        padding: .6875rem;
        font-size: .8rem
    }

    .top-header .navbar .nav-item .nav-link[data-bs-toggle=collapse]:after {
        margin-left: .5625rem
    }

    .top-header .header .customize-btn span {
        font-size: 0;
        padding-left: 0 !important
    }

    .top-header .header .btn-group-lg>.customize-btn.btn,
    .top-header .header .customize-btn.btn-lg {
        padding: .5rem 1rem
    }

    .double-header .navbar {
        padding-left: 0
    }

    .double-header .chat-items {
        height: calc(100vh - 25.25rem)
    }

    .muze-rtl.double-header .header-body>.row {
        margin-right: 0
    }

    .muze-rtl.double-header .navbar {
        padding-right: 0
    }

    .muze-rtl.top-header .navbar {
        margin-right: .5rem
    }

    .muze-rtl.top-header .header-body>.row {
        margin-right: 0
    }

    .muze-rtl.top-header .header .customize-btn .ps-2 {
        padding-right: 0 !important
    }

    body.container.double-header .main-content .header .container-fluid,
    body.container.double-header .main-content .header .container-lg,
    body.container.double-header .main-content .header .container-md,
    body.container.double-header .main-content .header .container-sm,
    body.container.double-header .main-content .header .container-xl,
    body.container.double-header .main-content .header .container-xxl,
    body.container.top-header .main-content .header .container-fluid,
    body.container.top-header .main-content .header .container-lg,
    body.container.top-header .main-content .header .container-md,
    body.container.top-header .main-content .header .container-sm,
    body.container.top-header .main-content .header .container-xl,
    body.container.top-header .main-content .header .container-xxl {
        max-width: 71.25rem
    }

    body.container.double-header:not(.muze-rtl) .navbar,
    body.container.top-header:not(.muze-rtl) .navbar {
        margin-left: 0
    }

    body.container.double-header.festive-template:not(.muze-rtl) .main-content .col-xxl-9>.p-xxl-5.border-end,
    body.container.top-header.festive-template:not(.muze-rtl) .main-content .col-xxl-9>.p-xxl-5.border-end {
        padding-right: 1rem !important
    }

    body.container.double-header.festive-template.muze-rtl .main-content .col-xxl-9>.p-xxl-5.border-end,
    body.container.top-header.festive-template.muze-rtl .main-content .col-xxl-9>.p-xxl-5.border-end {
        padding-left: 1rem !important
    }

    body.container.festive-template:not(.muze-rtl) .main-content .p-xxl-5 {
        padding-right: 1rem !important
    }

    body.container.help-center-template .after-header~.px-xxl-5,
    body.container.pricing-template .after-header~.px-xxl-5 {
        padding-right: 1rem !important
    }

    body.container.help-center-template .btn-soft-primary,
    body.container.pricing-template .btn-soft-primary {
        padding-left: .5rem;
        padding-right: .5rem
    }

    body.container.chat-template .muze-chats .px-xxl-5 {
        padding-right: 1rem !important
    }

    body.container.help-center-template .h-100.px-lg-5,
    body.container.pricing-template .h-100.px-lg-5 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }
}

@media screen and (max-width:1199px) {
    footer .navbar li {
        padding-left: 1.5625rem
    }

    .get-started-two {
        position: static;
        text-align: right;
        margin-bottom: -1.875rem
    }

    .title-box {
        min-height: initial
    }

    .menu-close {
        display: none
    }

    .navbar-toggler[aria-expanded=true] .menu-icon {
        display: none
    }

    .navbar-toggler[aria-expanded=true] .menu-close {
        display: block
    }

    .chat-template .nav.nav-pills {
        margin-top: .0625rem
    }

    .chat-template .nav.nav-pills .nav-link {
        padding: .3rem .9rem;
        font-size: .875rem
    }

    .has-xxl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 17.5rem
    }

    .has-xxl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: calc(100% - 17.5rem)
    }

    .chat-list {
        height: calc(100vh - 10.68rem)
    }

    .chat-list a .h6,
    .chat-list a h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: .5rem
    }

    .chat-items {
        height: calc(100vh - 19.75rem)
    }

    .message-box {
        padding: .9375rem
    }

    .message-box p {
        font-size: .875rem;
        line-height: 1.6
    }

    .message-files ul li {
        width: 25%
    }

    .top-header.sidebar-compact .navbar-brand img:not(.white-logo),
    .top-header.sidebar-icons .navbar-brand img:not(.white-logo) {
        display: -ms-flexbox;
        display: flex
    }

    .top-header.sidebar-compact .top-header-nav,
    .top-header.sidebar-icons .top-header-nav {
        opacity: 1;
        visibility: visible
    }

    .top-header.sidebar-compact .header-fixed,
    .top-header.sidebar-icons .header-fixed {
        left: 0
    }

    .top-header.sidebar-compact .main-content,
    .top-header.sidebar-icons .main-content {
        margin-left: 0
    }

    .top-header.sidebar-compact .back-arrow img,
    .top-header.sidebar-icons .back-arrow img {
        display: none
    }

    .top-header.sidebar-compact .back-arrow img.menu-close,
    .top-header.sidebar-icons .back-arrow img.menu-close {
        display: block
    }

    .top-header .navbar-brand.d-lg-none {
        display: inherit !important
    }

    .top-header .back-arrow.d-lg-none {
        display: block !important
    }

    .top-header .top-header-nav {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 5.75rem;
        left: 0;
        right: 0;
        padding: .625rem 0;
        transition: all ease .4s;
        border-bottom: .0625rem solid rgba(108, 117, 125, .125)
    }

    .top-header .top-header-nav .nav-item .nav-link {
        padding: .6875rem 1.375rem
    }

    .top-header.sidebar-menu .top-header-nav {
        display: block;
        opacity: 1;
        visibility: visible
    }

    .top-header.sidebar-menu .muze-hamburger img {
        display: none
    }

    .top-header.sidebar-menu .muze-hamburger img.menu-close {
        display: block
    }

    .top-header .navbar {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        border-left: 0
    }

    .top-header .navbar .nav-item .collapse-box .nav {
        padding: 0
    }

    .top-header .muze-hamburger {
        display: block !important
    }

    .top-header .header-body>.row {
        margin-left: calc(var(--bs-gutter-x)/ -2) !important
    }

    .double-header .navbar {
        padding-left: 0
    }

    .double-header .chat-items {
        height: calc(100vh - 23.7rem)
    }

    .double-header .chat-list {
        height: calc(100vh - 14.7rem)
    }

    .muze-rtl.top-header .navbar .nav-item .collapse-box {
        left: auto;
        right: 0
    }

    .muze-rtl.top-header .header-body>.row {
        margin-right: calc(var(--bs-gutter-x)/ -2)
    }

    .muze-rtl.top-header.muze-dark-mode .navbar {
        border: none;
        margin-right: 0;
        padding-right: 0
    }

    .muze-rtl .customize-body .form-check-inline {
        margin-right: 0
    }

    .muze-rtl footer .navbar {
        padding-left: 0
    }

    .muze-rtl footer .navbar li {
        padding-right: 1.5rem
    }

    body.container.top-header .header-body>.row {
        margin-left: calc(var(--bs-gutter-x)/ -2)
    }

    body.container.top-header .main-content .header .container-fluid,
    body.container.top-header .main-content .header .container-lg,
    body.container.top-header .main-content .header .container-md,
    body.container.top-header .main-content .header .container-sm,
    body.container.top-header .main-content .header .container-xl,
    body.container.top-header .main-content .header .container-xxl {
        max-width: 60rem
    }

    body.container.double-header .main-content .header .container-fluid,
    body.container.double-header .main-content .header .container-lg,
    body.container.double-header .main-content .header .container-md,
    body.container.double-header .main-content .header .container-sm,
    body.container.double-header .main-content .header .container-xl,
    body.container.double-header .main-content .header .container-xxl {
        max-width: 60rem
    }
}

@media screen and (min-width:992px) {

    .double-header .header-body>.row,
    .top-header .header-body>.row {
        margin-left: 0
    }

    .double-header .header-search {
        display: block
    }

    .double-header .header-search .input-group {
        background-color: transparent !important;
        border: none !important;
        border-left: .0625rem solid $secondary !important;
        border-radius: 0 !important;
        padding-left: 1.5625rem !important
    }

    .double-header .header-search .input-group .form-control {
        background-color: transparent;
        color: #ced4da
    }

    .double-header .header-search .input-group .form-control::-webkit-input-placeholder {
        color: #ced4da
    }

    .double-header .header-search .input-group .form-control::-moz-placeholder {
        color: #ced4da
    }

    .double-header .header-search .input-group .form-control:-ms-input-placeholder {
        color: #ced4da
    }

    .double-header .header-search .input-group .form-control:-moz-placeholder {
        color: #ced4da
    }

    .double-header .header-search .input-group img {
        -webkit-filter: brightness(3);
        filter: brightness(3)
    }

    .get-started-title {
        font-size: 1.75rem
    }

    .muze-icon {
        display: none
    }
}

@media screen and (max-width:991px) {
    .header-search {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        min-width: 100%;
        z-index: 9;
        padding: .9375rem;
        cursor: pointer;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .08) !important
    }

    .header-search form {
        width: calc(100% - 1.875rem)
    }

    .search-open {
        overflow: hidden;
        position: relative;
        z-index: 9
    }

    .search-open:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(18, 17, 17, .6);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10
    }

    .search-open .header-search {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        z-index: 11
    }

    .header-fixed {
        left: 0
    }

    .navbar-vertical.navbar-expand-lg {
        width: 100%;
        max-width: 15.625rem;
        position: fixed;
        left: 0;
        background-color: #fff;
        top: 4.54rem;
        height: calc(100vh - 4.54rem);
        z-index: 9;
        box-shadow: .125rem .5rem 1rem rgba(0, 0, 0, .04);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        transition: all ease .4s;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav {
        margin-left: 0;
        margin-right: 0;
        height: calc(100vh - 12.5rem)
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
        padding: .775rem 0
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
        padding-left: 1.8rem
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link {
        padding-left: 2.8rem
    }

    .navbar-vertical .navbar-collapse {
        height: 100%
    }

    .navbar-vertical .navbar-vertical-footer {
        padding: .375rem .9375rem
    }

    .navbar-vertical .simplebar-track {
        right: -.875rem
    }

    .sidebar-icons .navbar-vertical {
        max-width: 5rem
    }

    .sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav {
        height: calc(100vh - 10.625rem)
    }

    .sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
        margin-top: 1.25rem
    }

    .sidebar-icons .navbar-vertical .simplebar-track {
        right: 0
    }

    .sidebar-icons .header-fixed {
        left: 0
    }

    .sidebar-icons .navbar-brand img:not(.white-logo) {
        display: block
    }

    .sidebar-icons .main-content {
        margin-left: 0
    }

    .sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav {
        height: calc(100vh - 11.5625rem)
    }

    .sidebar-compact .main-content {
        margin-left: 0
    }

    .sidebar-compact .header-fixed {
        left: 0
    }

    .sidebar-compact .navbar-brand img:not(.white-logo) {
        display: block
    }

    .sidebar-compact .simplebar-track {
        right: 0
    }

    .sidebar-menu {
        overflow: hidden
    }

    .sidebar-menu .navbar-vertical {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        top: 4.29rem;
        height: calc(100vh - 4.29rem)
    }

    .sidebar-menu .muze-hamburger img {
        display: none
    }

    .sidebar-menu .muze-hamburger .menu-close {
        display: block
    }

    .muze-rtl.top-header .header-body .icon-blue-hover.ps-0 {
        padding-left: calc(var(--bs-gutter-x)/ 2) !important
    }

    .muze-rtl.top-header .header-body .notification {
        margin-right: 1.5rem !important
    }

    .muze-rtl.top-header .header .customize-btn span {
        padding-right: 0 !important
    }

    .muze-rtl.top-header .top-header-nav.navbar .nav-item .nav-link[data-bs-toggle=collapse]:after {
        margin-left: 0;
        margin-right: auto
    }

    .muze-rtl.top-header .top-header-nav.navbar .nav-item .collapse-box {
        padding-left: 0
    }

    .muze-rtl.top-header .after-header {
        margin-top: 6.0625rem
    }

    .muze-rtl.double-header .header-body .notification {
        margin-right: 1.5rem !important
    }

    .muze-rtl.double-header.search-open .header-search .input-group {
        border-color: #dee2e6 !important;
        border-left: .0625rem solid #dee2e6 !important
    }

    .muze-rtl.double-header .navbar .nav-item hr.bg-gray-50 {
        background-color: transparent !important
    }

    .muze-rtl.double-header .navbar .nav-item .nav-link[data-bs-toggle=collapse]:after {
        margin-left: 0;
        margin-right: auto
    }

    .muze-rtl.double-header .navbar .nav-item .collapse-box {
        padding-left: 0
    }

    .muze-rtl.sidebar-compact .main-content,
    .muze-rtl.sidebar-icons .main-content {
        margin-right: 0
    }

    .muze-rtl.sidebar-compact .header-fixed,
    .muze-rtl.sidebar-icons .header-fixed {
        right: 0
    }

    .muze-rtl .main-content {
        margin-right: 0
    }

    .muze-rtl .header-fixed {
        right: 0
    }

    .muze-rtl .header-body .notification {
        margin-right: 1.5rem !important
    }

    .muze-rtl .navbar-vertical.navbar-expand-lg {
        left: auto;
        right: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }

    .muze-rtl .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
        padding-left: 0;
        padding-right: 1.8rem
    }

    .muze-rtl .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link {
        padding-left: 0;
        padding-right: 1.8rem
    }

    .muze-rtl.sidebar-menu .navbar-vertical {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    .muze-dark-mode .muze-search>svg path {
        fill: #fff
    }

    .muze-dark-mode .header .navbar-brand img {
        display: none
    }

    .muze-dark-mode .header .navbar-brand img.white-logo {
        display: block
    }

    .muze-dark-mode .navbar-vertical.navbar-expand-lg {
        padding: 0
    }

    .muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-collapse {
        margin: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #1e1e1e
    }

    .muze-dark-mode.search-open .muze-search>svg path {
        fill: #1e1e1e
    }

    .muze-dark-mode.search-open .muze-search:hover>svg path {
        fill: $primary
    }

    .muze-dark-mode.search-open .header-search form .input-group.bg-white.border-gray-300 {
        background-color: #fff !important;
        border: .0625rem solid #dee2e6 !important
    }

    .muze-dark-mode.search-open .header-search .form-control {
        background-color: #fff !important
    }

    .signout i {
        display: none
    }

    .back-arrow,
    .muze-icon {
        display: none
    }

    .main-content {
        margin-left: 0;
        margin-top: 4.5625rem
    }

    .navbar {
        padding: .75rem 1rem;
        border-bottom: .0625rem solid rgba(108, 117, 125, .125)
    }

    .navbar .dropdown-toggle:after {
        display: none
    }

    .navbar.navbar-light .navbar-toggler {
        border-color: transparent
    }

    .navbar-light .navbar-collapse:before {
        border-top-color: #e3ebf6
    }

    .avatar.avatar-sm {
        width: 2.25rem;
        min-width: 2.25rem;
        height: 2.25rem
    }

    .avatar.avatar-circle .avatar-sm-status {
        bottom: -.0625rem;
        right: -.0625rem;
        width: .75rem;
        min-width: .75rem;
        height: .75rem
    }

    .overlay-center .btn {
        min-width: 10.9375rem;
        padding-left: .625rem;
        padding-right: .625rem
    }

    .check-list .form-check-input {
        margin-right: 0
    }

    .check-list .form-check-label {
        margin-left: 1rem !important
    }

    .double-header.search-open:before {
        z-index: 8
    }

    .double-header.search-open .header-search {
        display: -ms-flexbox;
        display: flex
    }

    .double-header.search-open .muze-search svg path {
        fill: #1e1e1e
    }

    .double-header.sidebar-compact .double-header-nav,
    .double-header.sidebar-menu .double-header-nav {
        opacity: 1;
        visibility: visible;
        display: block
    }

    .double-header .header-search {
        display: none
    }

    .double-header .main-content {
        margin-top: 5.0625rem
    }

    .double-header .muze-search svg path {
        fill: #fff
    }

    .double-header .muze-hamburger img {
        -webkit-filter: brightness(10);
        filter: brightness(10)
    }

    .double-header .double-header-nav {
        padding: 0;
        transition: all ease .4s;
        position: absolute;
        top: 5rem;
        left: 0;
        right: 0;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        display: none
    }

    .double-header .double-header-nav .navbar {
        padding-left: 0
    }

    .double-header .navbar .nav-item .nav-link[data-bs-toggle=collapse]:after {
        margin-left: auto
    }

    .double-header .navbar .nav-item .collapse-box {
        position: static;
        min-width: 100%;
        box-shadow: none;
        border-radius: 0;
        padding-left: 1.875rem
    }

    .double-header .navbar .nav-item .collapse-box .nav {
        padding: 0
    }

    .double-header .chat-items {
        height: calc(100vh - 18.6rem)
    }

    .double-header .chat-list {
        height: calc(100vh - 9.6rem)
    }

    .top-header .navbar {
        padding: 0
    }

    .top-header .top-header-nav {
        top: 4.3125rem
    }

    .top-header .top-header-nav.navbar .nav-item .nav-link[data-bs-toggle=collapse]:after {
        margin-left: auto
    }

    .top-header .top-header-nav.navbar .nav-item .collapse-box {
        position: static;
        min-width: 100%;
        box-shadow: none;
        border-radius: 0;
        padding-left: 1.875rem
    }

    .top-header .main-content {
        margin-top: 5.0625rem
    }

    .chat-items {
        height: calc(100vh - 17.9rem)
    }

    .chat-list {
        height: calc(100vh - 9rem)
    }

    .muze-rtl.double-header .header-body>.row {
        margin-left: 0;
        margin-right: calc(var(--bs-gutter-x)/ -2)
    }

    .muze-rtl .form-check .form-check-input {
        margin-right: -1.5em
    }

    body.container:not(.muze-rtl) .main-content .p-xxl-5,
    body.container:not(.muze-rtl) .main-content .px-xxl-5 {
        padding-left: 0 !important
    }

    body.container.muze-rtl .main-content .p-xxl-5,
    body.container.muze-rtl .main-content .px-xxl-5 {
        padding-right: 0 !important
    }

    body.container:not(.top-header):not(.top-header) .navbar-vertical.navbar-expand-lg {
        background-color: #fff;
        border-left: none
    }

    body.container.double-header .main-content .header .container-fluid,
    body.container.double-header .main-content .header .container-lg,
    body.container.double-header .main-content .header .container-md,
    body.container.double-header .main-content .header .container-sm,
    body.container.double-header .main-content .header .container-xl,
    body.container.double-header .main-content .header .container-xxl,
    body.container.top-header .main-content .header .container-fluid,
    body.container.top-header .main-content .header .container-lg,
    body.container.top-header .main-content .header .container-md,
    body.container.top-header .main-content .header .container-sm,
    body.container.top-header .main-content .header .container-xl,
    body.container.top-header .main-content .header .container-xxl {
        max-width: 45rem
    }

    body.container.double-header .header:before {
        height: 5.0625rem
    }

    body.container.double-header .double-header-nav .navbar-expand-lg .navbar-nav {
        margin: 0
    }

    body.container.festive-template:not(.muze-rtl) .main-content .p-xxl-5 {
        padding-right: 1rem !important
    }

    body.container.festive-template:not(.muze-rtl) .main-content .col-md-5.col-xxl-3 .p-xxl-5,
    body.container.festive-template:not(.muze-rtl) .main-content .col-md-5.col-xxl-3 .px-xxl-5 {
        padding-right: 0 !important;
        padding-left: 1rem !important
    }

    body.container.help-center-template .after-header~.px-xxl-5,
    body.container.pricing-template .after-header~.px-xxl-5 {
        padding-left: 1rem !important
    }

    body.container.chat-template .muze-chats .px-xxl-5 {
        padding-left: 1rem !important
    }

    .signup-template .display-3.h1,
    .signup-template h1.display-3 {
        font-size: 2.5rem;
        line-height: 3.5rem
    }
}

@media screen and (max-width:767px) {
    .card-table th {
        padding: .8125rem .9375rem
    }

    .card-table td {
        padding: .9375rem
    }

    .get-startedtwo {
        position: static
    }

    .get-started-img {
        margin-bottom: -16.25rem;
        bottom: 0;
        position: relative;
        right: 0
    }

    footer {
        text-align: center;
        padding-top: .625rem
    }

    footer .navbar {
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0
    }

    footer .navbar li {
        padding-left: 0;
        padding-right: .9375rem
    }

    .notification .status {
        position: absolute;
        top: -.125rem;
        right: -.25rem
    }

    .title-box {
        min-height: auto
    }

    .get-started-two {
        position: static;
        margin-bottom: -1.875rem
    }

    .invoice-template .card-table td {
        padding: 1.25rem .9375rem
    }

    .login-form {
        max-width: 100%
    }

    .signup-template .display-3 {
        font-size: 3.5rem;
        line-height: 4.5rem
    }

    .signup-template .cover-fit {
        height: auto
    }

    .user-profile-template .avatar.avatar-lg {
        width: 3.125rem;
        min-width: 3.125rem;
        height: 3.125rem
    }

    .user-profile-template .avatar-lg-status {
        width: .875rem;
        height: .875rem;
        min-width: .875rem
    }

    .group-cards .title-box {
        min-height: auto
    }

    .lh-lg {
        line-height: 1.8 !important
    }

    .pricing-template .top-less {
        margin-top: -6.875rem
    }

    .help-center-template form .btn {
        width: 100%
    }

    .ql-container.ql-snow {
        height: 7.5rems
    }

    .step-list .h5,
    .step-list h5 {
        font-size: 1rem
    }

    .new-projects-template form .circle-xl {
        width: 3.75rem;
        height: 3.75rem
    }

    .activity-list .list-group-item:not(:last-child):before {
        left: 2.6875rem
    }

    .chat-template .circle-lg {
        width: 2.375rem;
        height: 2.375rem;
        min-width: 2.375rem
    }

    .chat-template .circle-lg svg {
        width: 1rem
    }

    .chat-template .col-auto.has-xxl-4 {
        width: 100%
    }

    .chat-template .col.has-xxl-8 {
        width: 100%
    }

    .chat-template .nav.nav-pills {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow: auto
    }

    .has-xxl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .has-xxl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .chat-list {
        height: auto;
        border-bottom: .0625rem solid #e9ecef
    }

    .chat-list a {
        padding: .625rem;
        border-left: 0;
        border-bottom: .3125rem solid transparent
    }

    .chat-list a.active {
        background-color: transparent
    }

    .chat-list .media .media-body {
        display: none
    }

    .chat-list .simplebar-content {
        display: -ms-flexbox;
        display: flex;
        overflow-x: auto
    }

    .chat-items {
        height: calc(100vh - 16.5rem);
        margin-bottom: 7.5rem
    }

    .message-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: #fff
    }

    .message-footer .form-control {
        height: 3.125rem;
        padding: 0
    }

    .message-footer a:not(.btn) {
        margin-right: 1.25rem
    }

    .chat-item {
        padding: .375rem 0
    }

    .message-box {
        padding: .625rem
    }

    .message-box:before {
        border-bottom: .875rem solid transparent;
        bottom: 21%
    }

    .attachment {
        padding: .5rem .9375rem;
        font-size: .875rem
    }

    .message-box p {
        font-size: .8125rem
    }

    .project-management-template .get-startedthree {
        position: relative;
        margin: 1.25rem 0
    }

    .calendar-template .fc-toolbar.fc-header-toolbar {
        position: relative;
        padding-top: 3rem
    }

    .calendar-template .fc-toolbar-chunk .fc-toolbar-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;
        text-align: center;
        font-size: 1.375rem
    }

    .nav-tabs.nav-tabs-line .nav-link {
        padding: .65rem .75rem;
        font-size: .875rem
    }

    .double-header .double-header-nav {
        top: 4.25rem
    }

    .double-header .double-header-nav .navbar {
        padding-left: 0
    }

    .double-header .btn-group-lg>.customize-btn.btn,
    .double-header .customize-btn.btn-lg {
        line-height: 1.28rem;
        padding: .5rem .875rem
    }

    .double-header .customize-btn span {
        font-size: 0;
        padding-left: 0 !important
    }

    .double-header .chat-items {
        height: calc(100vh - 21.5rem)
    }

    .double-header .chat-list {
        height: auto
    }

    .top-header .btn-group-lg>.customize-btn.btn,
    .top-header .customize-btn.btn-lg {
        line-height: 1.28rem;
        padding: .5rem .875rem
    }

    .top-header .customize-btn span {
        font-size: 0;
        padding-left: 0 !important
    }

    .muze-rtl .header-body .notification .status {
        right: auto;
        left: -.25rem
    }

    .muze-rtl .header .customize-btn .ps-2 {
        padding-right: 0 !important
    }

    .muze-rtl.top-header .after-header {
        margin-top: 4.0625rem
    }

    .muze-rtl footer .navbar li {
        padding: .3125rem 1rem
    }

    .muze-rtl.festive-template .col-auto.ps-0.ps-md-3 {
        padding-left: calc(var(--bs-gutter-x)/ 2) !important
    }

    .muze-rtl .activity-list .list-group-item:not(:last-child):before {
        right: 2.6rem
    }

    .muze-dark-mode.chat-template .chat-list a.active {
        background-color: transparent
    }

    .muze-dark-mode .message-footer {
        background-color: #1a1919
    }

    .muze-dark-mode .nav-tabs.nav-tabs-line .nav-link:not(.active) {
        border-color: #343a40
    }

    .list-group .list-group-item .avatar.avatar-circle .avatar-sm-status {
        right: .125rem;
        bottom: .125rem
    }

    body.container {
        max-width: 100%;
        padding: 0
    }

    body.container:not(.muze-rtl) .main-content .p-xxl-5,
    body.container:not(.muze-rtl) .main-content .px-xxl-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    body.container.double-header .main-content .header:before {
        height: 4.6875rem
    }

    body.container.double-header .main-content .header .container-fluid,
    body.container.double-header .main-content .header .container-lg,
    body.container.double-header .main-content .header .container-md,
    body.container.double-header .main-content .header .container-sm,
    body.container.double-header .main-content .header .container-xl,
    body.container.double-header .main-content .header .container-xxl {
        max-width: 100%
    }

    body.container.double-header .main-content .header .px-xxl-5 {
        padding-right: 0 !important
    }

    body.container.double-header.festive-template:not(.muze-rtl) .main-content .col-xxl-9>.p-xxl-5.border-end {
        padding-left: 1rem !important
    }

    body.container.double-header.festive-template.muze-rtl .main-content .col-xxl-9>.p-xxl-5.border-end {
        padding-right: 1rem !important
    }

    body.container.top-header .main-content .header .px-xxl-5 {
        padding-right: 0 !important
    }

    body.container.top-header.festive-template:not(.muze-rtl) .main-content .col-xxl-9>.p-xxl-5.border-end {
        padding-left: 1rem !important
    }

    body.container.top-header.festive-template.muze-rtl .main-content .col-xxl-9>.p-xxl-5.border-end {
        padding-right: 1rem !important
    }

    body.container.muze-rtl .main-content .p-xxl-5,
    body.container.muze-rtl .main-content .px-xxl-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    body.container.project-management-template .main-content .bg-gray-100.p-md-5 .col-xl-6 {
        width: 100%
    }

    body.container.project-management-template:not(.muze-rtl) .get-startedthree {
        right: -6.25rem
    }

    body.container.festive-template:not(.muze-rtl) .main-content .col-md-5.col-xxl-3 .p-xxl-5,
    body.container.festive-template:not(.muze-rtl) .main-content .col-md-5.col-xxl-3 .px-xxl-5 {
        padding-right: 1rem !important
    }

    body.container .muze-chats {
        border-right: none !important
    }
}

@media screen and (max-width:639px) {
    .display-4 {
        font-size: 2.5rem
    }

    .customize-sidebar {
        width: 25rem
    }

    .grid-option>.dropdown-menu {
        min-width: 20.625rem
    }

    footer .fs-16 {
        font-size: .875rem !important
    }

    footer .navbar li {
        padding-top: 0
    }

    footer .navbar li a {
        font-size: .875rem
    }

    .get-started-img {
        margin-bottom: -11.875rem;
        padding: .625rem
    }

    .get-started-two {
        position: static;
        margin-bottom: -1.875rem
    }

    .simple-login-form {
        width: 100%;
        padding: 2.5rem;
        margin-bottom: .9375rem
    }

    .signup-header {
        padding: 1.875rem .9375rem 15.625rem
    }

    .message-files ul li {
        width: 33.333%
    }

    .new-projects-template form span.text-muted {
        font-size: .8125rem
    }

    .project-management-template .get-startedthree {
        right: -6.25rem;
        margin-top: .625rem
    }

    .double-header .header .customize-btn,
    .top-header .header .customize-btn {
        position: fixed;
        bottom: 1.25rem;
        right: 1.25rem;
        z-index: 9;
        border-radius: 50%;
        width: 2.625rem
    }

    .double-header .header .btn-group-lg>.customize-btn.btn,
    .double-header .header .customize-btn.btn-lg,
    .top-header .header .btn-group-lg>.customize-btn.btn,
    .top-header .header .customize-btn.btn-lg {
        padding: .5rem .675rem
    }

    .double-header .main-content,
    .top-header .main-content {
        margin-top: 4.3125rem
    }

    .muze-rtl.double-header .header .customize-btn,
    .muze-rtl.top-header .header .customize-btn {
        margin-right: 0 !important
    }

    body.container.double-header .main-content .header .customize-btn,
    body.container.top-header .main-content .header .customize-btn {
        padding-left: .5rem;
        padding-right: .5rem;
        margin-left: 0 !important;
        margin-right: 0 !important
    }
}

@media screen and (max-width:479px) {
    .display-4 {
        font-size: 2.1rem
    }

    .customize-sidebar {
        width: 100%
    }

    .customize-body {
        height: calc(100vh - 15rem)
    }

    .sidebar-style img {
        max-width: 5rem
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item .nav-link {
        font-size: .75rem
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box>.nav .nav-link {
        font-size: .75rem
    }

    .sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item .nav-link {
        font-size: 0
    }

    .sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box>.nav .nav-link {
        font-size: .75rem
    }

    .sidebar-compact .navbar-vertical.navbar-expand-lg {
        max-width: 7.5rem
    }

    .sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.nav-link span:not(.docs-version) {
        font-size: .75rem
    }

    .sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box {
        left: 7.5rem
    }

    .sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box>.nav {
        min-width: 11.875rem
    }

    .sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item>.collapse-box>.nav .nav-link {
        font-size: .75rem
    }

    .sidebar-compact .signout .btn-group-lg>.btn,
    .sidebar-compact .signout .btn-lg {
        padding: .625rem .9375rem;
        height: 3rem
    }

    .grid-option>.dropdown-menu {
        min-width: 17.5rem
    }

    .grid-option .dropdown-body .dropdown-item {
        padding: .9375rem .625rem
    }

    .profile-dropdown>.dropdown-menu {
        min-width: 13.125rem
    }

    .signup-template .display-3 {
        font-size: 2.1rem;
        line-height: 2.7rem
    }

    .simple-login-form {
        padding: 1.25rem
    }

    .pricing-template p.fs-20.lh-lg {
        font-size: 1.125rem;
        line-height: 1.6 !important
    }

    .all-projects-template .avatar {
        min-width: 2.25rem;
        height: 2.25rem;
        width: 2.25rem
    }

    .chat-header .h4,
    .chat-header h4 {
        font-size: 1.125rem;
        line-height: 1.6rem
    }

    .chat-item-inner .chat-message {
        padding-top: 0
    }

    .message-files ul li {
        width: 50%;
        margin-bottom: .625rem
    }

    .chat-template .btn-group-xl>.btn,
    .chat-template .btn-xl {
        height: 2.75rem;
        font-size: .875rem
    }

    .muze-chats .avatar {
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem
    }

    .muze-chats .avatar-status {
        min-width: .75rem;
        width: .75rem;
        height: .75rem
    }

    .file-manager-template .h6,
    .file-manager-template h6 {
        font-size: .875rem
    }

    .file-manager-template .dropdown.end-0 {
        right: .3125rem !important
    }

    .file-manager-template .dropdown.top-0 {
        top: .3125rem !important
    }

    .calendar-template .fc-toolbar.fc-header-toolbar {
        display: block;
        padding-top: 1.75rem;
        text-align: center
    }

    .calendar-template .fc-toolbar-chunk {
        margin-top: .625rem
    }

    .sidebar-style .form-check-label img {
        max-width: 5rem
    }
}

@media screen and (max-width:420px) {
    .header .navbar-brand img {
        max-width: 4.0625rem
    }

    .header .icon-blue-hover .muze-search svg {
        max-width: 1rem
    }

    .header .icon-blue-hover .dropdown a.ms-4 {
        margin-left: 1rem !important
    }

    .header .icon-blue-hover .dropdown a svg {
        max-width: 1rem
    }

    body.container.double-header .main-content .header:before {
        height: 4.3125rem
    }
}

/* CURSOR */
.cursor-pointer {
    cursor: pointer!important;
}

.animate.spin {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin { 
    from { 
        -ms-transform: rotate(0deg); 
    } to { 
        -ms-transform: rotate(360deg); 
    }
}
@-moz-keyframes spin { 
    from { 
        -moz-transform: rotate(0deg); 
    } to { 
        -moz-transform: rotate(360deg); 
    }
}
@-webkit-keyframes spin { 
    from { 
        -webkit-transform: rotate(0deg); 
    } to { 
        -webkit-transform: rotate(360deg); 
    }
}
@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}

.catalog-background {
    background-position: left;
}

.redeem-background {
    background-position: right;
}


.alert-background {
    background-repeat: no-repeat;
    background-size: cover;

    .alert-overlay {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.6) 30%, rgba(0,0,0,0) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.z-index-top {
    z-index: 999;
}

.z-index-background {
    z-index: 1;
}

.svg-icon-menu {
    width: 24px;
    max-height: 16px;
}

.brand-icon-lg {
    max-width: 100px;
}

.fs-overlay {
    position: absolute;
    background-color: #ffffff;
    z-index: 9999;
    width: 100%;
    top: 0;
    left: 0;
}

.reachout-logo {
    width: 50%
}


.btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;
}
.btn-primary:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
    color: $white;
}

.btn-secondary {
    background-color: $secondary;
    color: $white;
}

.sidebar-compact, body {
    background-color: $bg-color;
}

/* CUSTOM STYLES */

/* MISSIONS */
.res-font-size{
    font-size: 1vw;
}

.mission-square{
    flex: 1;
    aspect-ratio: 1 / 1;
}

.numberLineHeight{ 
    line-height: 0.9;
}



@media only screen and (max-width: 1400px) {
    .res-font-size{
        font-size: 2vw;
    }
}
@media only screen and (max-width: 1200px) {
    .res-font-size{
        font-size: 1.7vw;
    }
}
@media only screen and (max-width: 768px) {
    .res-font-size{
        font-size: 2.5vw;
    }
}


/* MY REDEEMS */

.redeemContainer {
    position: relative;
}

.redeemContainer img {
    height: auto;
}

.redeemContainer .beneficios-btn {
    color: $secondary;
    position: absolute;
    top: 20%;
    left: 12%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 500;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 15px;
}

.redeemContainer .beneficios-btn:hover {
        background-color: $primary;
        color: $white;
    }


@media only screen and (max-width: 1500px) {
    .redeemContainer .beneficios-btn {
        font-size: 12px;
        padding: 10px 20px;
    }
}
@media only screen and (max-width: 1200px) {
    .redeemContainer .beneficios-btn {
        top: 18%;
        left: 10%;
    }
}
@media only screen and (max-width: 800px) {
    .redeemContainer .beneficios-btn {
        left: 12%;
    }
}